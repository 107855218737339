.actions-filter.filter-blocks {

  .filter-block.IN_PROGRESS {
    .line {
      border-color: var(--ORANGE_COLOR);
      background: var(--ORANGE_COLOR);
    }

    &.active {
      background: var(--ORANGE_COLOR);
    }
  }

  .filter-block.APPROVED {
    .line {
      border-color: var(--GREEN_COLOR);
      background: var(--GREEN_COLOR);
    }

    &.active {
      background: var(--GREEN_COLOR);
    }
  }

  .filter-block.LOCKED {
    .line {
      border-color: #585858;
      background: #585858;
    }

    &.active {
      background: #585858;
    }
  }
}
