.content-block-component {
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  box-shadow: 0 2px 2px #00000029;
  margin-bottom: 30px;

  .content-block-error {
    margin: -1px -1px 0 -1px;
  }

  .content-block-inner {
    padding: 20px 30px;
    box-sizing: border-box;
  }

  .block-header {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #1C304D;
    margin-top: 0;
    margin-bottom: 20px;
  }
}
