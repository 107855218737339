.project-group-hierarchy {
  margin: -20px -30px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .hierarchy-top-block {
    padding: 20px 30px 0 30px;
  }

  .group-buttons {
    margin-top: 8px;
  }

  .hierarchy-scroll {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 0;
    flex: auto;

    .flex-row {
      height: 100%;
      padding: 0 30px;

      &:after {
        content: "";
        min-width: 30px;
      }
    }

    .hierarchy-level {
      min-width: 290px;
      width: 290px;
      height: 264px;
      border: 1px solid #ECECEC;
      overflow-y: auto;
      margin-right: 20px;

      .hierarchy-level-inner {
        padding: 10px 10px;
      }

      &.small {
        height: 187px;
      }

      &.collapsed-level {
        height: auto;
        border: none;
        width: auto;
        min-width: auto;
        margin-right: 15px;

        .hierarchy-level-inner {
          padding: 0;

          .hierarchy-item-icon {
            display: none;
          }
        }
      }

      &:last-child {
        margin-right: 0;

        &.collapsed-level .hierarchy-item-icon {
          display: block;
        }
      }
    }

    .hierarchy-item {
      padding: 8px 20px 8px 12px;
      background: #ffffff;
      border-radius: 10px;
      cursor: pointer;
      display: flex;

      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000000;

      &:hover {
        background: rgba(#F2F4F7, 0.5);

        .hierarchy-more-icon button {
          opacity: 1;
        }
      }

      &.active {
        background: #F2F4F7;
      }

      .hierarchy-item-label {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .hierarchy-item-icon {
        width: 24px;
        max-width: 24px;
        min-width: 24px;
        position: relative;
        color: #b3b3b3;

        svg {
          position: absolute;
          transform: translateX(-5px);
        }

        .active {
          color: var(--PRIMARY_BACKGROUND_COLOR);
        }
      }

      .hierarchy-more-icon {
        width: 16px;
        position: relative;

        button {
          position: absolute;
          transform: translateY(-3px);
          transition: opacity 0.4s ease-in-out;
          opacity: 0;
        }
      }
    }
  }

  .please-add-hint {
    display: flex;
    justify-content: center;
    margin-top: 92px;

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #1C304D;

      margin-top: 75px;
      margin-right: 20px;
    }
  }

}
