.award-dialog {

  .MuiDialog-paper {
    min-width: 428px;
    box-sizing: border-box;
  }

  .label-with-margin {
    margin: 22px 0 0 0;
  }

  .field-label {
    min-width: 160px;
  }

  .input-with-margin {
    margin: 0 16px;
  }

  .MuiFormControl-fullWidth {
    margin-bottom: 30px;
  }

  .drop-area {
    border: 3px dashed var(--NEUTRAL_TEXT_COLOR);
    border-radius: 10px;
    padding: 30px;
    margin: 30px 0;
  }

  .award-image-wrapper {
    text-align: center;
    height: 200px;
    margin: 30px 0;

    .award-image {
      max-width: 240px;
      max-height: 200px;
    }
  }

}
