.content-block-error {
  background-color: var(--ORANGE_BACKGROUND_COLOR);
  color: var(--ORANGE_COLOR);
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 30px;

  svg {
    transform: translateY(7px);
    margin-right: 10px;
  }
}
