.link-dialog.MuiDialog-root {
  .MuiDialog-paper {
    min-width: 500px;
    max-width: 500px;
  }

  .MuiDialogTitle-root {
    color: #2c2323;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0;
    padding-right: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiFormControl-root {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 10px;

    .MuiButton-root {
      font-size: 0.875rem;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}
