.rating-reason-dialog .MuiFormControl-root {
  color: var(--PRIMARY_TEXT_COLOR) !important;

  label {
    z-index: 3;
  }

  .Mui-disabled {
    -webkit-text-fill-color: var(--PRIMARY_TEXT_COLOR) !important;
  }
}
