.project-attribute-item {
  border-radius: 10px;
  padding: 8px 16px;
  margin-top: 16px;
  background-color: #ffffff;

  .text-muted {
    color: rgba(0, 0, 0, 0.54);
  }

  .actions-column {
    text-align: right;
    padding-left: 16px;
    display: flex;
  }

  .dnd-trigger {
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    svg {
      cursor: grab;
    }
  }
}
