.custom-dialogs {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;

  z-index: 1300;
  overflow: auto;

  &.show {
    opacity: 1;
    pointer-events: all;
  }

  .custom-dialog {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;

    margin: 30px auto;
    background-color: var(--PRIMARY_TEXT_COLOR);
    border-radius: 0;
    padding: 27px 30px;
    min-width: 320px;
    max-width: 640px;
    box-sizing: border-box;
    box-shadow: 0 2px 2px #00000029;
    border: 1px solid #EFEFEF;

    .loading-overlay {
      overflow: visible;
    }

    h2 {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.36px;
      font-weight: bold;
      margin: 0;
    }

    .buttons {
      margin-right: -6px;
      margin-bottom: -11px;
    }

    &.small {
      max-width: 432px;
    }

    &.large {
      max-width: 960px;
    }

    &.xlarge {
      max-width: calc(100vw - 100px);
    }

    &.open {
      opacity: 1;
      pointer-events: all;
    }
  }
}
