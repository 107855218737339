.floating-inputs-suffix {
  flex: calc(1 / 4);
  padding: 8px 16px;
  color: inherit;
}

.floating-inputs .k-disabled {
  border-color: black;
  color: black;
}
