.base-Popper-root {
  z-index: 2;
}

.dropdown-li {
  min-width: 150px;
}

.context-menu-element-name {
  > div {
    top: 50%;
    border: 1px solid rgb(151, 151, 151);
    width: 90%;
    margin-left: 5%;
  }
}

.context-menu {
  z-index: 10;
}
