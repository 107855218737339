.rich-text-animation-container.k-animation-container {
  z-index: 3000 !important;
}

.image-tool-dialog,
.grid-tool-dialog {
  .k-floating-label-container {
    width: 47.5%;

    &:nth-child(1) {
      margin-right: 2.5%;
    }

    &:nth-child(2) {
      margin-left: 2.5%;
    }

    margin-bottom: 10px;
  }

  button[aria-label="minimize"],
  button[aria-label="maximize"],
  button[aria-label="restore"] {
    display: none !important;
  }

  .k-window-content > button.k-button {
    margin: 5px;
  }

  .k-window-content > div.k-button-group {
    margin: 5px;
    margin-left: 0;
  }

  .MuiSvgIcon-root {
    font-size: inherit;
  }
}

.grid-tool-dialog-no-close-button {
  .k-window-titlebar-actions {
    display: none;
  }
}
