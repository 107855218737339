.whitelabel-dialog {

  .MuiPaper-root {
    width: 720px;
    max-width: none;
    box-sizing: border-box;
    padding: 0;

    .loading-overlay {
      box-sizing: border-box;
      padding: 32px;
      width: 100%;
      min-height: auto;
    }
  }

  .MuiButton-textPrimary {
    color: var(--PRIMARY_BACKGROUND_COLOR);
  }

  .logos {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 12px;
  }

  .info-boxes {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    margin-bottom: 15px;
  }

  .logo-wrapper {

    .img-preview {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
    }

    svg, img:not(.draft-logo) {
      max-width: 200px;
      max-height: 100px;
    }
    .draft-logo {
      visibility: hidden;
      position: absolute;
      pointer-events: none;
    }

    &.large-image {
      .img-preview {
        height: 200px;
      }
      svg, img:not(.draft-logo) {
        max-width: 320px;
        max-height: 200px;
      }
    }
  }

  .colors-wrapper {

    .inputs {
      display: flex;

      & > * {
        margin-right: 24px;
      }
    }
  }

  .upload-area {
    padding: 40px;
    margin-top: 24px;
    border: 2px dashed #EFEFEF;
    border-radius: 5px;
    text-align: center;

    &.drag-over {
      border-color: #333;

      * {
        pointer-events: none;
      }
    }

    label {
      cursor: pointer;
      color: var(--PRIMARY_BACKGROUND_COLOR);
      font-weight: 700;

      input {
        display: none;
      }
    }
  }

  .color-input {
    width: 128px;
    position: relative;

    .MuiFormLabel-root {
      transform: translate(0, 1.5px) scale(0.75);
    }

    div.MuiInputBase-root.Mui-disabled {
      color: var(--SECONDARY_TEXT_COLOR);

      &:before {
        border-bottom-style: solid;
        border-bottom-color: #ECECEC;
      }
    }

    .color {
      width: 14px;
      height: 14px;
      position: absolute;
      left: 0;
      bottom: 9px;
      border-radius: 50%;
      border: 1px solid #ECECEC;
    }

    input {
      padding-left: 24px;
      text-transform: uppercase;
    }
  }
}
