.evaluation-reason {
  text-align: right;

  button {
    color: inherit !important;

    svg {
      margin-right: 4px;
    }
  }
}
