.project-advanced-filter {
  position: absolute;
  top: 16px;
  right: 0;
  display: flex;
  line-height: 28px;
  height: 28px;

  > .filter-items {
    flex: auto;
    display: flex;

    .filter-item {
      font-size: 14px;
      line-height: 28px;
      font-weight: normal;
      color: #000000;
      border-radius: 14px;
      background-color: #ffffff;
      margin-right: 6px;
      padding: 0 4px 0 10px;

      button {
        transform: translateX(3px);
        width: 24px;
        height: 24px;
      }
    }
  }

  > button {
    flex: 1;
    min-width: 28px;
    min-height: 28px;
  }
}
