.general-attribute-dialog {

  .simple-table-sort {
    margin-bottom: 0;
  }

  .search-filter-box {
    margin-top: 20px;
  }

  .MuiTablePagination-root {
    margin-right: -18px;
  }
}
