.home-dashboard {
    width: 100%;
    padding: 90px 50px 50px;
    box-sizing: border-box;

    & > h1 {
        font-size: 50px;
        line-height: 75px;
        margin: 0 0 0 0;
    }
    & > h2 {
        font-size: 35px;
        margin: 0 0 25px 7px;
    }
    &-grid {
        max-width: calc(1300px + 400px);
        display: grid;
        grid-template-columns: 1fr 400px;
        grid-template-rows: auto;
        grid-gap: 30px 20px;
        grid-template-areas:
            "widgets user"
            //"filters user"
            "map     user"
            "social  user";
    }
    &-widgets {
        grid-area: widgets;
        display: flex;

        & > *:not(:last-child) {
            margin-right: 20px;
        }
    }
    &-filters {
        grid-area: filters;
    }
    &-map {
        grid-area: map;
    }

    &-user {
        grid-area: user;
        position: relative;
        background: var(--PRIMARY_TEXT_COLOR);
        text-align: center;
        padding: 10px 10px 0 0;

        .logo {
            max-width: 110px;
            max-height: 70px;
        }

        .pic { // temporary
            width: 100%;
        }

        .user-tenant-name {
            margin-top: 0px;
            white-space: normal;
            overflow-wrap: break-word;
            text-align: left;
        }

    }
    &-social {
        grid-area: social;
        display: flex;

        & > *:not(:last-child) {
            margin-right: 20px;
        }

        & > a img { // temporary
            max-width: 100%;
            max-height: 380px;
        }
    }

    .map-proj-details-card-wrapper {
        display: grid;
        grid-template-columns: 100% 0;
    }
    .card-visible {
        display: grid;
        grid-template-columns: calc(70% - 10px) 30%;
        grid-gap: 10px;
    }
    .full-name {
        margin-left: 50px;
        font-size: 28px;
        line-height: 23px;
        color: #1C304D;
        font-weight: bold;
        white-space: normal;
        overflow-wrap: break-word;
    }
    .company {
        margin-left: 50px;
        font-size: 20px;
        color: darkgray;
        text-align: left;
    }

    .projects {
        margin-left: 50px;
        font-size: 18px;
        color: black;
        width: 100%;

        svg {
            margin-left: 5px;
            color: royalblue;
            transform: rotate(140deg);
        }
    }

    .table{
        display: table;
        border-spacing: 1px;
    }

    .row {
        display: table-row;
        height: auto;

    }
    .cell {
        white-space: nowrap;
        display: table-cell;
        vertical-align: middle;
    }

}
