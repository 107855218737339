body {
  margin: 0;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  overflow-anchor: none;
}

a {
  text-decoration: none;
  color: inherit;
}

/* fabs */
.popover-shown .fabs {
  right: 39px;

  &.static {
    right: 22px;
  }
}

.fabs {
  position: fixed;
  bottom: 105px;
  right: 22px;
  z-index: 3;

  a.link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    line-height: 49px;
    padding-top: 10px;
  }
}

button.MuiFab-root {
  display: block;
  margin-top: 15px;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: inherit;
    pointer-events: none;
  }

  &:hover:after,
  &:active:after {
    background: rgba(0, 0, 0, 0.1);
  }

  svg.fab-svg {
    transform: translate(0, 4px);
  }

  &.MuiFab-secondary {
    background-color: var(--SECONDARY_BACKGROUND_COLOR);
  }

  &.MuiFab-primary {
    background-color: var(--PRIMARY_BACKGROUND_COLOR);

    &:disabled {
      background-color: #a7a7a7;
    }

    &.gray {
      background-color: #50505080;

      &:hover,
      &:active {
        background-color: #7f7f7f;
      }
    }
  }
}

/* collapsible */
.collapsible {
  opacity: 1;
  max-height: none;
  transition: opacity 0.3s ease-in-out;

  &.collapsed {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }
}

.collapsible-header {
  cursor: pointer;
  display: flex;

  &.collapsed > svg {
    transform: rotate(0deg);
  }

  > div {
    flex: auto;
  }

  > svg {
    transform: rotate(180deg);
  }
}

/* LoadingOverlay */
.loading-overlay {
  min-height: 100%;

  &.full-height {
    height: 100%;
  }

  &.auto-height {
    min-height: 0;
  }
}

div.loading-overlay > .loading-overlay-overlay {
  background: rgba(#f0f2f5, 0.3);

  .loading-overlay-content .loading-overlay-spinner svg circle {
    stroke: #1890ff;
  }
}

/* MUI Button */
button.MuiButton-textPrimary {
  color: var(--PRIMARY_BACKGROUND_COLOR);
}

/* MUI IconButton */
button.MuiIconButton-colorPrimary {
  color: #1890ff;
}

button.MuiIconButton-colorSecondary {
  color: var(--RED_COLOR);
}

button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  background-color: var(--PRIMARY_BACKGROUND_COLOR);
  box-shadow: 0 2px 2px #00000029;
  border-radius: 0;
  padding: 10px;

  .MuiButton-label {
    font-size: 20px;
    line-height: 24px;
    text-transform: none;
    font-weight: 300;
    letter-spacing: 0.19px;
    color: var(--PRIMARY_TEXT_COLOR);
  }
}

/* MUI Paper */
.MuiPaper-root.MuiPaper-rounded {
  box-shadow: 0 2px 2px #00000029;
  border: 1px solid #efefef;
  border-radius: 0;
}

.MuiListItem-root {
  .MuiListItemIcon-root {
    min-width: 28px;
  }
}

.MuiMenu-paper.MuiPopover-paper {
  .MuiMenu-list {
    option {
      padding: 6px 12px;
      cursor: pointer;

      &:hover {
        background-color: rgba(#1890ff, 0.1);
      }
    }
  }
}

/* Mui Checkbox */
.MuiCheckbox-root {
  &.checkLine {
    margin-right: 4px;
    margin-left: -8px;
    padding: 4px;
  }
}

/* MUI Popover */
.MuiPopover-root.tooltip-popover {
  pointer-events: none;

  &.text-tooltip {
    .MuiPopover-paper {
      background: #002965;
      border-radius: 5px;
      padding: 8px 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }

    .muted {
      opacity: 0.5;
    }

    pre {
      margin: 0;
    }
  }
}

.MuiPopover-root.award-popover {
  pointer-events: none;

  .MuiPopover-paper {
    padding: 10px 20px;
  }

  .award-header {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.22px;
    color: #000000de;
    text-align: center;
  }

  .award-image {
    max-width: 50px;
    max-height: 50px;
    display: block;
    margin: 5px auto 5px auto;
  }

  .award-title {
    font-size: 14px;
    line-height: 19px;
    color: #777b7f;
    text-align: center;
  }
}

.MuiPopover-paper .popover-inner {
  padding: 0;

  .advanced-table-column-lock {
    padding-right: 10px;

    svg {
      transform: translateX(-7px);
    }
  }

  .advanced-table-search-field {
    padding: 10px 10px 10px 10px;

    &.spt-search-menu {
      padding: 0 !important;
    }

    svg {
      color: #00000066;
    }

    input {
      padding-right: 30px;
    }

    button:not(.search-option) {
      position: absolute;
      right: 6px;
    }

    button.search-option {
      display: block;
      border-radius: 0;
      width: 100%;
      padding: 10px 25px 10px 25px;
      text-align: left;

      &.spt-search-selected {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
  }
}

.MuiPopover-paper .popover-check-tree {
  .check-tree {
    margin: 10px 10px 10px 5px;
  }
}

/* MUI progress-bar */
.MuiLinearProgress-root {
  &.MuiLinearProgress-colorPrimary {
    background-color: #d6e9f5;
  }

  .MuiLinearProgress-dashedColorPrimary {
    background: transparent;
  }

  .MuiLinearProgress-bar2Buffer {
    background-color: var(--SECONDARY_BACKGROUND_COLOR);
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: var(--SECONDARY_BACKGROUND_COLOR);
  }
}

/* MUI disabled select icon */
.MuiInputBase-input.MuiSelect-root.Mui-disabled {
  padding-right: 14px;
}

.MuiSvgIcon-root.MuiSelect-icon.Mui-disabled {
  display: none;
}

/* Mui Autocomplete */
.MuiAutocomplete-listbox {
  .MuiListSubheader-root {
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin: 10px 0;
  }

  .MuiListSubheader-sticky {
    position: static;
  }

  .option-with-icon {
    width: 100%;
    display: flex;
    margin-right: -10px;

    .option-with-icon-title {
      flex: auto;
      word-break: break-word;
    }

    .MuiSvgIcon-root {
      transform: translateX(5px);
      color: var(--PRIMARY_BACKGROUND_COLOR);
      width: 24px;
      height: 24px;
    }
  }

  .favorite-option {
    .favorite-option-title {
      font-size: 14px;
      line-height: 19px;
      color: var(--ORANGE_COLOR);
    }
  }
}

/* Mui Tooltip */
.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    font-size: 14px;
    font-weight: normal;
  }
}

/* MUI Snackbar */
.MuiSnackbar-root {
  pre {
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

/* MUI Backdrop */
.MuiBackdrop-root:not(.MuiBackdrop-invisible) {
  background-color: rgba(0, 0, 0, 0.5);
}

/* MUI Checkbox */
.MuiCheckbox-colorPrimary.Mui-checked:not(.Mui-disabled),
.MuiCheckbox-indeterminate:not(.Mui-disabled) {
  color: var(--PRIMARY_BACKGROUND_COLOR) !important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: var(--PRIMARY_BACKGROUND_LOW_OPACITY_COLOR) !important;
}

/* MUI Radio */
.MuiIconButton-colorPrimary.Mui-checked:not(.Mui-disabled) {
  color: var(--PRIMARY_BACKGROUND_COLOR) !important;
}

/* MUI OutlinedInput */
fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #0000001f !important;
}

label.MuiFormLabel-root.Mui-focused {
  color: #008dc9;
}

label.MuiFormLabel-root.Mui-focused.Mui-error {
  color: var(--RED_COLOR);
}

.MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #008dc9 !important;
}

.MuiOutlinedInput-root.Mui-focused.Mui-error fieldset.MuiOutlinedInput-notchedOutline {
  border-color: var(--RED_COLOR) !important;
}

div.MuiInputBase-root.Mui-disabled {
  color: #00000061;
}

div.MuiFormControl-root:hover .MuiInput-underline:before {
  border-bottom-color: var(--NEUTRAL_TEXT_COLOR);
}

div.MuiInput-underline:before {
  border-bottom-color: var(--NEUTRAL_TEXT_COLOR);
  border-bottom-width: 2px;
}

div.MuiInput-underline:after,
.k-input-flat:after,
.k-input-flat:focus-within,
.k-picker.k-focus,
.k-picker:focus-within {
  border-bottom-color: #008dc9 !important;
}

.k-input-flat.k-disabled,
.k-picker-flat.k-disabled {
  background-color: transparent !important;
}

.k-list {
  .k-list-item.k-selected {
    color: var(--SECONDARY_BACKGROUND_COLOR);
  }
}

.read-only {
  div.MuiInput-underline:before,
  div.MuiInput-underline:after {
    border-bottom-color: #ffffff !important;
  }
}

div.MuiSelect-select:focus,
div.MuiSelect-select:active {
  background-color: var(--PRIMARY_TEXT_COLOR);
}

p.Mui-error,
.text-danger {
  color: var(--RED_COLOR);
}

.text-warning {
  color: var(--ORANGE_COLOR);
  font-size: 12px;
}

/* MUI Tabs */
.MuiTabs-root {
  .MuiTabs-scroller.MuiTabs-fixed {
    .MuiTabs-indicator {
      display: none;
    }

    .MuiTabs-flexContainer {
      border-bottom: 4px solid #8792a2;

      .MuiTab-root {
        padding: 10px;
        min-height: 46px;
        min-width: auto;
        margin-right: 35px;

        font-size: 20px;
        font-weight: bold;
        line-height: 26px;
        color: #1c304d80;
        text-transform: uppercase;
        display: flex;
        flex-direction: row;

        svg {
          margin: 0 7px 0 0;
        }

        &.Mui-selected {
          background: var(--PRIMARY_BACKGROUND_COLOR) 0 0 no-repeat padding-box;
          border-radius: 0 10px 0 0;
          color: var(--PRIMARY_TEXT_COLOR);
        }
      }
    }
  }
}

/* MUI Dialog */
.MuiDialog-root {
  &.small .MuiDialog-paper {
    min-width: 256px;
    max-width: 416px;
    box-sizing: border-box;
  }

  &.large .MuiDialog-paper {
    min-width: 50vw;
    max-width: 80vw;
    box-sizing: border-box;
  }

  &.full-height {
    .MuiDialog-paper {
      height: calc(100% - 64px);

      .simple-table-sort {
        height: calc(100% - 105px);

        .MuiTableContainer-root {
          height: 100%;
        }
      }
    }
  }

  button.third-action {
    float: left;
    margin-left: -6px;
  }

  .MuiDialog-paper {
    border-radius: 0;
    padding: 20px 30px;

    .MuiDialogActions-root {
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: -5px;
      padding-bottom: 0;
    }

    .fit-dialog-content {
      margin-left: -30px;
      margin-right: -30px;

      .MuiDialogTitle-root,
      .MuiDialogContent-root,
      .MuiDialogActions-root {
        padding-left: 31px;
        padding-right: 31px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
      }

      .MuiDialogActions-root {
        padding-right: 20px;
        padding-bottom: 0;
      }

      .MuiDialogTitle-root button {
        right: 20px;
      }

      .MuiDialogContent-root.modal-dialog-content {
        padding-left: 0;
        padding-right: 0;

        .modal-search {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    .MuiDialogTitle-root {
      padding: 0;
      margin-bottom: 12px;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.36px;
      font-weight: bold;

      button {
        position: absolute;
        right: 18px;
        top: 16px;
        z-index: 1;
      }
    }

    .MuiDialogContent-root {
      padding-left: 0;
      padding-right: 0;
    }

    .dialog-body {
      margin: 29px 0 19px 0;
    }

    p.dialog-content {
      margin: 29px 0 19px 0;
      line-height: 24px;
      letter-spacing: 0.29px;
    }

    p.Mui-error {
      margin-top: 0;
      margin-left: 0;
      padding-bottom: 0;
      position: absolute;
    }

    .MuiTablePagination-toolbar {
      p {
        margin: 0;
        line-height: 32px;
      }
    }

    .text-right {
      margin-right: -6px;
      margin-bottom: -11px;
    }

    .tag-input {
      .MuiOutlinedInput-adornedEnd {
        padding-right: 4px;
      }
    }

    .tag-chips {
      padding-top: 10px;
    }

    .MuiButton-root {
      text-transform: none;
      font-size: 16px;
      letter-spacing: 0.29px;
      padding: 6px;
      font-weight: bold;

      &.MuiButton-textSecondary {
        color: var(--RED_COLOR);
      }
    }
  }
}

.loading-overlay.select-file-dialog {
  overflow-y: auto;
}

/* page */
.page-header {
  margin: 37px 50px 20px 50px;

  &.large {
    h1 {
      height: 48px;
      font-size: 36px;
      line-height: 48px;
    }
  }

  .right-header-button {
    background: #e5eaf2;
    padding: 9px 10px;
    color: var(--PRIMARY_BACKGROUND_COLOR) !important;
    text-transform: uppercase;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    svg {
      margin-right: 8px;
      fill: var(--PRIMARY_BACKGROUND_COLOR) !important;
      width: 16px;
      height: 16px;
    }

    &a svg {
      transform: translateY(3px);
    }

    &.Mui-disabled {
      opacity: 0.5;
    }
  }

  button.back-button {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.29);
    border: 1px solid #575756;
    border-radius: 20px;
    height: 36px;
    line-height: 36px;
    font-size: 20px;
    padding: 0 5px;
    min-width: 168px;
    background: transparent;
    cursor: pointer;
    position: relative;
    margin-bottom: 26px;

    svg {
      position: absolute;
      left: 6px;
      top: 8px;
    }
  }

  h1 {
    font-size: 20px;
    line-height: 26px;
    height: 36px;
    margin: 0;
    padding: 0 3px;
    background-color: var(--PRIMARY_TEXT_COLOR);
    display: inline-block;

    .MuiSvgIcon-root {
      font-size: 2.5rem;
      transform: translateY(4px);
      margin-right: 6px;
    }

    svg {
      transform: translateY(4px);
      margin-right: 6px;

      .svg-fill {
        fill: #000000;
        opacity: 1 !important;
        stroke: none;
      }
    }
  }
}

.MuiFormControl-root.MuiFormControl-fullWidth {
  margin-bottom: 8px;
}

.page-layout {
  margin: 0 50px 5px 50px;

  .content-block {
    background: var(--PRIMARY_TEXT_COLOR);
    box-shadow: 0 2px 2px #00000029;
    border: 1px solid #efefef;
    padding: 30px;
    margin-bottom: 30px;

    h2 {
      font-size: 36px;
      line-height: 48px;
      font-weight: bold;
      color: #1c304d;
      margin: 0 0 30px 0;

      &.large {
        font-size: 50px;
        line-height: 66px;
        margin: 0;
      }
    }

    button.header-button {
      font-size: 14px;
      line-height: initial;
      color: var(--PRIMARY_BACKGROUND_COLOR);
      text-transform: uppercase;
      margin-right: -8px;

      svg {
        margin-right: 4px;
      }
    }

    button.top-right-button {
      float: right;
    }

    .muted {
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #2d2a3e;
      text-transform: uppercase;
    }

    .amount {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      color: #1c304d;
      position: absolute;
      transform: translateY(-30px);
    }
  }
}

.page-content {
  margin: 0 50px 5px 50px;

  &.small {
    width: 456px;
    margin-left: auto;
    margin-right: auto;
  }

  &.medium {
    width: 966px;
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
  }

  &.large {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
  }
}

.steps {
  width: 640px;
  margin: 30px auto 30px auto;
}

.page-title.small {
  text-align: left;
  width: 456px;
  margin: auto;
  font-size: 24px;
  line-height: 32px;
  padding: 26px 0 28px 28px;
  background: top left no-repeat url("../svg/union_1.svg");
  transform: translate(4px, 0);
}

.page-content {
  padding: 30px;
  border: 1px solid #eaeaea;
  box-sizing: border-box;

  h2 {
    font-size: 20px;
    line-height: 26px;
    color: #2c2323;
    margin: 0 0 28px 0;
    border-bottom: 2px solid #000000e0;
    padding-bottom: 4px;
  }

  .MuiInputBase-fullWidth {
    margin-bottom: 20px;
  }

  .MuiButton-containedPrimary {
    text-transform: none;
    background-color: var(--PRIMARY_BACKGROUND_COLOR);
    box-shadow: 0 2px 2px #00000029;
    border-radius: 20px;
    font-size: 20px;
    line-height: 26px;
    font-weight: normal;
    color: var(--PRIMARY_TEXT_COLOR);
    min-width: 168px;

    margin-top: 8px;

    &:hover,
    &:active {
      background-color: var(--PRIMARY_BACKGROUND_LOW_OPACITY_COLOR);
    }
  }
}

.MuiMenuItem-root.menu-item-close-button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  line-height: 24px;
  padding: 9px;
  background-color: transparent !important;
}

.MuiPopover-paper > a {
  color: inherit;
  text-decoration: none;
}

/* page with table */
.page-with-table {
  padding-top: 30px;
  box-sizing: border-box;

  .table-loading {
    margin-right: -5px;
    padding-right: 5px;
    min-height: auto;
  }

  .page-header {
    margin-top: 0;
    margin-bottom: 27px;

    .title-box {
      padding-top: 10px;
    }

    h1 {
      width: auto;
      padding: 0 8px 0 0;
      font-size: 24px;
      height: 37px;
      line-height: 37px;
      margin-top: 0;
      background: transparent;

      svg {
        margin-right: 10px;
        transform: translateY(6px);
      }
    }

    .search-col {
      flex: auto;
      padding-top: 16px;
      position: relative;

      .search-input {
        width: 100%;
        background: var(--PRIMARY_TEXT_COLOR);
        border-radius: 10px;
        border-width: 0;
        height: 50px;
        box-sizing: border-box;
        padding: 12px 12px 12px 69px;
        line-height: 26px;
        font-size: 16px;

        &::placeholder {
          color: rgba(0, 0, 0, 0.5);
          text-align: left;
        }

        &:focus {
          outline: none;
        }
      }

      svg {
        fill: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 30px;
        top: 30px;
        pointer-events: none;
      }
    }

    .search-box {
      flex: auto;
      position: relative;
      padding: 0 20px 0 0;

      .search-input {
        width: 100%;
        background: #ececec;
        border-width: 0;
        height: 60px;
        box-sizing: border-box;
        padding: 17px 17px 17px 59px;
        line-height: 26px;
        font-size: 20px;
        box-shadow: 0 2px 2px #00000029;

        &::placeholder {
          color: #808080;
          text-align: left;
        }

        &:focus {
          outline: none;
        }
      }

      > svg {
        fill: #808080;
        position: absolute;
        left: 48px;
        top: 18px;
        pointer-events: none;
      }
    }

    .sort-col {
      width: 264px;
      margin-left: 30px;
      padding-top: 16px;

      .sort-input {
        height: 50px;
        box-sizing: border-box;
        background: var(--PRIMARY_TEXT_COLOR);
        border-radius: 10px;
        border-width: 0;
        padding: 12px 20px;

        .MuiInputBase-root {
          flex: auto;
          margin-right: 20px;
        }

        .MuiInput-underline:after,
        .MuiInput-underline:before {
          border-bottom: 0 !important;
        }

        .MuiSelect-select {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #00000099;
          padding-top: 11px;
        }

        .direction-toggle {
          padding: 12px;
          margin: -12px;
          transform: rotateX(180deg);

          transition: transform 0.3s ease-in-out;

          &.desc {
            transform: rotateX(0deg);
          }
        }
      }
    }
  }

  .column-link {
    color: inherit;
    text-decoration: none;

    svg {
      width: 24px;
      height: 24px;
      transform: translateY(-2px);
      position: absolute;
    }

    .column-link-with-icon {
      margin-left: 28px;
    }
  }

  .status-cell {
    width: 160px;
  }

  .action-cell,
  .action-header-cell {
    text-align: right;
    min-width: 175px;
    width: 175px;

    & > button,
    & > a {
      margin-left: 5px;
    }
  }

  .action-header-cell {
    padding-right: 50px !important;
    padding-left: 0 !important;
  }

  .page-header,
  .page-content {
    > .flex-col:first-child {
      width: 361px;
      margin-right: 21px;
      flex: none;
    }

    > .flex-col:last-child {
      flex: auto;
    }
  }

  .page-header .search-box {
    margin-right: 10px !important;
  }

  .page-content {
    padding: 0;
    border: none;
  }
}

.search-box {
  cursor: text;
  margin: -23px -30px 0 -30px;
  padding: 23px 30px 20px 30px;

  svg {
    position: absolute;
    color: #b4b4b4;
  }

  input {
    border: none;
    box-shadow: none;
    outline: none;
    height: 24px;
    line-height: 24px;
    font-weight: 400;
    font-size: 16px;
    color: #707070;
    margin-left: 30px;
    width: calc(100% - 30px);

    &:focus {
      outline: none;
    }
  }
}

/* Chip */
@keyframes backgroundScroll {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 42px 0;
  }
}

.tag-chips {
  margin-top: -5px;
  margin-right: -5px;

  .MuiChip-root {
    margin-right: 5px;
    margin-top: 5px;
  }
}

.MuiChip-root.uploading,
.MuiChip-root.uploading:focus {
  color: rgba(0, 0, 0, 0.43);
  border: none;
  cursor: default;
  background-image: url("../gfx/loading-scroll.png");
  animation: backgroundScroll 1s linear infinite;
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-radius: 16px;
    background-color: transparent;
    background-image: url("../gfx/loading-solid.png");
    background-repeat: no-repeat;
    background-size: 50% 100%;
    border: 1px solid #efefef;
  }

  span,
  svg {
    z-index: 1;
  }
}

/* MuiFormControl read-only */
.MuiFormControl-root.read-only,
.MuiAutocomplete-root.read-only {
  .MuiFormLabel-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.54);
  }

  .MuiInputBase-input {
    color: #000000;
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }
}

/* custom-dialog */
.custom-dialogs .custom-dialog {
  .custom-dialog-header {
    margin-bottom: 16px;
  }
}

.custom-dialog {
  .dialog-body.negative-margin-bottom {
    margin-bottom: -28px;
  }

  .dialog-body.negative-margin {
    margin: 0 -31px -28px -31px;
  }

  .modal-search {
    margin: 0 -31px 0 -31px;
  }

  .modal-pagination {
    margin: 0 -31px 0 0;
  }
}

.modal-table.MuiTable-root,
.modal-table .MuiTable-root {
  margin: 0 -31px 0 -31px;
  width: calc(100% + 62px);

  .actions-cell {
    text-align: right;
  }

  &.with-borders {
    .MuiTableBody-root {
      .MuiTableRow-root:first-child {
        .MuiTableCell-body {
          padding-top: 10px;
        }
      }

      .MuiTableCell-root {
        border-bottom: 2px solid #d0d0d0;
      }

      .MuiTableCell-body {
        padding: 10px 16px 10px 0;
      }
    }
  }

  .MuiTableHead-root {
    background: #efefef;

    .MuiTableCell-head {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      color: #707070;
      padding: 16px 16px 16px 0;
      border-bottom: none;

      &:first-child {
        padding-left: 32px;
      }

      &:last-child {
        padding-right: 32px;
      }
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root:first-child {
      .MuiTableCell-body {
        padding-top: 12px;
      }
    }

    .MuiTableCell-root {
      border-bottom: none;
    }

    .MuiTableCell-body {
      padding: 0 16px 0 0;

      &:first-child {
        padding-left: 32px;
      }

      &:last-child {
        padding-right: 32px;
      }
    }
  }
}

.rich-text-content,
.public-DraftEditor-content {
  a {
    color: var(--PRIMARY_BACKGROUND_COLOR);
  }
}

.print-report-button .MuiMenu-list {
  .MuiListItem-button {
    color: var(--PRIMARY_BACKGROUND_COLOR);
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;

    &:hover,
    &.submenu-opened {
      background-color: rgba(63, 81, 181, 0.04);
    }
  }
}

/* general */
.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.text-muted {
  color: #d5d5d5;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-bold {
  font-weight: bold;
}

.text-hyphens {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.break-work {
  word-break: break-word;
}

button.with-left-icon svg {
  margin-right: 5px;
}

button.with-right-icon svg {
  margin-right: 5px;
}

.number-no-appearance {
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-45 {
  transform: rotate(45deg);
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-row-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.flex-justify-center {
  justify-content: center;
}

.flex-auto {
  flex: auto;
}

.flex-2 {
  flex: 2;
}

.flex-col,
.footer-buttons button {
  margin-left: 15px;
  margin-right: 15px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
}

.box-shadow {
  box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.3);
}

.input-shadow {
  box-shadow: 0 3px 3px #00000033;
}

.nowrap {
  white-space: nowrap;
}

.no-padding {
  padding: 0 !important;
}

.small-padding-bottom {
  padding-bottom: 8px;
  position: relative;
}

.padding-bottom {
  padding-bottom: 16px;
}

.no-margin {
  margin: 0 !important;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.w-100 {
  width: 100%;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.no-events {
  pointer-events: none;
}

.chars-left {
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: right;
  line-height: 1.66;
  position: relative;
  z-index: 2;
}

.chars-left-up {
  position: absolute;
  bottom: 20px;
  right: 0;
  font-size: 0.75rem;
  margin-right: 5px;
  margin-top: -30px;
  text-align: right;
  line-height: 1.66;
  z-index: 2;
}

.header-evaluation {
  margin-top: 30px;
}

.value-unit {
  font-size: 0.75rem;
  margin-top: -25px;
  text-align: right;
  line-height: 1.66;
}

.overflow-y-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-auto {
  overflow: auto;
}

.clickable-awardprogress-float-right {
  cursor: pointer;
  width: 100%;
  order: 2;
}

.capitalized {
  text-transform: capitalize !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 0;
  pointer-events: none;
}

.select-add-button {
  animation-name: fade-in;
  animation-duration: 0.6s;
}

.selected-add-button {
  animation-name: fade-in-out;
  animation-duration: 3s;
  color: #007c05 !important;
}

@keyframes fade-in-out {
  0% {
    opacity: 0%;
  }

  20% {
    opacity: 100%;
  }

  80% {
    opacity: 100%;
  }

  100% {
    opacity: 0%;
  }
}

@keyframes fade-in {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}

.flex-spacer {
  flex-grow: 9999;
}

div.file-upload-restrictions {
  color: #ff391d;
  margin-top: 10px;
}

.horizontal-flip {
  transform: scaleX(-1);
}
