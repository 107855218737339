.decision-section {
  .modal-table.MuiTable-root .MuiTableBody-root {
    .kpi-cell {
      width: 30%;
    }

    .projectAttribute-cell {
      width: 30%;
    }

    .MuiTableCell-body.actions-cell.with-actions {
      padding-top: 5px;
      padding-bottom: 5px;

      .MuiButton-root {
        height: 30px;
      }
    }
  }
}
