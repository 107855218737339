

.ratingSystemGoals {

  .empty-title {
    margin-top: 160px;
  }

  .add-rating-goals {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: normal;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0 10px;

    .disabled {
      opacity: 0.5;
      cursor: auto;
    }

    span {
      cursor: pointer;
      color: var(--PRIMARY_BACKGROUND_COLOR);

      svg {
        display: block;
        margin-left: auto;
        margin-right: auto;
        color: var(--PRIMARY_BACKGROUND_COLOR);
        width: 50px;
        height: 50px;
        margin-bottom: 5px;
      }
    }
  }
}

.ratingSystemGoals-empty {
  display: block;
}

.ratingSystemGoals-hasBlocks {
  display: grid;
  grid-template-columns: repeat(auto-fill, 440px);
  grid-gap: 30px;
  margin-bottom: 50px;
}
