.MuiTableContainer-root.advanced-table {
  background: var(--PRIMARY_TEXT_COLOR);
  box-shadow: 0 2px 2px #00000029;
  border: 1px solid #EFEFEF;
  border-radius: 0;
  position: relative;
  height: 100%;
  box-sizing: border-box;

  &.with-error {
    border-top: none;
  }

  &.read-only {
    .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root .collapse-trigger {
      transform: translate(-28px, 2px);
    }
  }

  .footer-buttons {
    padding: 10px;
    margin: 0 100px;
    text-align: center;
    position: relative;
  }

  .MuiTable-root {
    table-layout: fixed;
    overflow-y: scroll;
    transform: translate3d(0, 0, 0);
  }

  .drag-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    cursor: grabbing;
  }

  .hover-controls {
    position: absolute;
    margin-top: -10px;
    height: 20px;
    z-index: 3;
    transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 0.5;
    pointer-events: none;
    width: 100%;

    &:before {
      content: "";
      position: absolute;
      border-top: 2px solid rgba(#1890ff, 0.5);
      top: 9px;
      right: 0;
      left: 0;
    }

    &:hover {
      opacity: 1;
    }

    &.hidden {
      opacity: 0;
    }

    .controls-fabs {
      padding-left: 20px;
      padding-right: 20px;
      display: inline-block;
      pointer-events: all;
    }

    button {
      margin-top: -2px;
      margin-left: 7px;
      width: 24px;
      height: 24px;
      min-height: 24px;
      display: inline-block;
      pointer-events: all;

      span {
        min-height: 24px;
        height: 24px;
        padding-top: 2px;

        svg {
          font-size: 20px;
        }
      }
    }
  }

  .column-grouping-title {
    position: absolute;
    text-align: center;
    z-index: 1;
    left: 0;
    top: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-size: 12px;
    padding: 0 10px;
    box-sizing: border-box;

  }

  .MuiTableHead-root {

    .MuiTableRow-head.single-line-header {
      .MuiTableCell-head {
        height: 46px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 8px 0 8px 12px;

        .column-header {
          padding: 0 2px 0 18px;
        }

        .dnd-trigger {
          height: auto;
          top: 2px;
          left: -5px;

          svg {
            margin-bottom: 0;
          }
        }

        .header-actions {
          bottom: -2px;
        }

        .header-text {
          font-size: 20px;
          line-height: 26px;
          color: var(--NEUTRAL_TEXT_COLOR);
          justify-content: center;
        }
      }
    }

    .MuiTableCell-head {
      height: 88px;
      padding: 0;
      box-sizing: border-box;
      vertical-align: bottom;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.29px;
      color: #000000DE;
      border-bottom: 2px solid #EFEFEF;
      min-width: 100px;
      position: sticky !important;
      top: 0;
      left: auto;
      z-index: 2;
      background: #ffffff;

      &.sticky {
        left: 0;
        z-index: 4;
      }

      cursor: default;
      user-select: none;

      &.group .column-header {
        background-color: #c0c0c0;
        color: var(--PRIMARY_TEXT_COLOR);

        &.first-in-group {
        }

        &.last-in-group {
          margin-right: 10px;
        }
      }

      .columns-button {
        position: absolute;
        left: 5px;
        top: 5px;
        z-index: 1;
      }

      .column-header {
        position: relative;
        height: 100%;
        padding: 8px 2px 8px 30px;
        max-height: 77px;
        box-sizing: border-box;
        background-color: var(--PRIMARY_TEXT_COLOR);
        color: var(--NEUTRAL_TEXT_COLOR);

        .header-text {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          overflow: hidden;
          word-break: break-word;
          hyphens: auto;

          text-align: left;
          font-weight: normal;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0.8px;
          text-transform: uppercase;

          .column-header-addon {
            margin-bottom: 16px;
            margin-right: 16px;

            .MuiInputBase-input {
              text-transform: none;
            }
          }

          &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 20px
          }
        }
      }

      .resize-trigger {
        position: absolute;
        top: 0;
        right: -5px;
        height: 100%;
        padding: 0 5px;
        cursor: col-resize;

        .resize-trigger-inner {
          border-right: 2px solid transparent;
          height: 36px;
          margin-top: 11px;
        }
      }

      .dnd-trigger {
        position: absolute;
        bottom: 0;
        left: 3px;
        cursor: grab;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        svg {
          height: 24px;
          margin-bottom: 3px;
          color: rgba(0, 0, 0, 0.54);
        }
      }

      &:last-child .column-header {
        padding-right: 17px;

        .resize-trigger {
          right: 5px;
        }

        .header-actions {
          right: 15px;
        }
      }

      .header-actions {
        position: absolute;
        bottom: 3px;
        right: 10px;
        padding: 5px;
        background-color: rgba(#ffffff, 0.9);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }

      &:hover .column-header {
        .dnd-trigger, .header-actions {
          opacity: 1;
        }
      }
    }
  }

  .header-ghost {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: grab;

    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #D7EAF6 10%, #D7EAF6 90%, rgba(0, 0, 0, 0) 100%);
    height: 80px;
    padding: 8px 0 8px 30px;
    box-sizing: border-box;
    vertical-align: middle;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.29px;
    color: #000000DE;
    opacity: 0.66;
  }

  .single-line-overlay {
    .header-ghost {
      height: 46px;
    }
    .header-landing {
      height: 44px;
    }
  }

  .header-landing {
    position: absolute;
    display: block;
    border-bottom: 2px solid #019FE3;
    height: 78px;
  }

  .row-ghost {
    position: absolute;

    border: 1px solid #019FE3;

    background: rgba(#D7EAF6, 0.2);
    box-shadow: inset 0 0 10px 5px #D7EAF6;
    box-sizing: border-box;
    opacity: 0.66;
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      position: relative;

      &.selected,  &.selected .MuiTableCell-root {
        background: #99B3FF;
      }

      &:hover .MuiTableCell-root .dnd-trigger {
        opacity: 1;
      }

      &.drag {
        background-color: #EEF2F3;
      }

      .changed {
        background-color: #F8F5B3 !important;
      }

      .danger {
        background-color: #FFC3BB !important;
      }

      .MuiTableCell-root {
        padding: 0 0 0 30px;
        box-sizing: border-box;
        height: 57px;
        vertical-align: middle;
        border-bottom: 0;
        color: #000000DE;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.36px;
        word-break: break-word;

        &.footer-row {
          background-color: #ffffff;
        }

        &.sticky {
          position: sticky !important;
          top: 0;
          left: 0;
          z-index: 3;
        }

        &.last-in-group {
          position: relative;
          padding-right: 20px;

          &:after {
            content: '';
            border-right: 2px dashed #969696;
            position: absolute;
            right: 10px;
            top: 0;
            bottom: 0;
          }
        }

        &.first-in-group {
          position: relative;

          &:before {
            content: '';
            border-left: 2px dashed #969696;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
          }
        }

        .error-mark {
          border-left: 5px solid #FF391D;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
        }

        input[type=number] {
          text-align: right;
          -moz-appearance: textfield;

          &::placeholder {
            text-align: left;
          }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }

        .type-icon, .adornment {
          svg {
            transform: translateY(5px);
            display: inline-block;
            margin-right: 8px;
          }

          &.end-adornment {
            svg {
              transform: none;
              margin-right: 0;
            }

            float: right;
          }
        }

        .dnd-trigger {
          position: absolute;
          left: 3px;
          padding-top: 3px;
          cursor: grab;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }

        .collapse-trigger {
          position: absolute;
          padding: 0;
          z-index: 1;
          transform: translate(-28px, 8px);

          svg {
            color: rgba(0, 0, 0, 0.5);

            &:hover {
              color: #000000DE;
            }
          }

          &.active svg {
            color: #000000DE;
          }
        }

        &:last-child {
          padding-right: 26px;
        }
      }
    }
  }

  .empty-cell {
    padding: 0 0 12px 0;
    box-sizing: border-box;
    height: 60px;
    font-size: 20px;
    line-height: 48px;
    letter-spacing: 0.36px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
  }

  .column-grouping {
    position: absolute;
    padding: 0;
    left: -1px;
    top: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    height: 78px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.content-block-error.advanced-table-error {
  border: 1px solid #EFEFEF;
  border-bottom: none;
  background: rgba(255, 57, 29, 0.2);
  color: #FF391D;
}

.advanced-table-expand-collapse {
  display: flex;
  padding: 10px 16px;

  button {
    flex: 1;
  }
}
