
.dock {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin-bottom: 16px;

  .pointer-move-area {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10000;
    cursor: col-resize;
  }

  .wrapper-panel {
    flex: auto;
    overflow: auto;
  }

  .dock-panel {
    flex: 1;
    min-width: 420px;
    position: relative;
    padding-left: 20px;

    .scroll-area {
      height: 100%;
      overflow: hidden auto;
      box-sizing: border-box;
      padding: 0 30px 0 30px;
      width: 100%;
      box-shadow: 0 2px 2px #00000029;
      border: 1px solid #EFEFEF;
      background: var(--PRIMARY_TEXT_COLOR);
    }

    .close-button {
      position: absolute;
      top: 29px;
      right: 30px;
      color: #000000;
      background-color: var(--PRIMARY_TEXT_COLOR);
      z-index: 1;
    }

    .resize-pin {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 0;
      border-left: 8px solid transparent;
      user-select: none;
      cursor: col-resize;

      &:hover {
        border-color: #1890ff;
      }
    }
  }

  table {
    width: 100%;
  }

  h3 {
    font-size: 20px;
    line-height: 26px;
    margin: 0;
  }

  .title-row td {
    padding-bottom: 10px;
    vertical-align: bottom;
    padding-right: 16px;

    &:last-child {
      padding-right: 0;
    }

    label {
      display: block;
      font-size: 16px;
      line-height: 21px;
      text-transform: uppercase;
    }
  }

  .data-row td {
    padding-bottom: 20px;
    padding-right: 16px;

    &:last-child {
      padding-right: 0;
    }

    span {
      font-size: 14px;
      line-height: 19px;
    }
  }

  tr:last-child td {
    padding-bottom: 0;
  }

  .data-block {
    margin: 0 -30px;
    padding: 15px 30px;
    margin-bottom: 36px;

    h4 {
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
      text-transform: uppercase;
      margin-bottom: 20px;
      margin-top: 0;
    }

    pre {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }

  .left-column {
    margin-right: 30px;
  }

  .right-column {
    margin-left: 30px;
  }

  .links, .empty {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .add-button {
    transform: translateY(-3px);
  }

  .collapsible {
    padding: 0;

    > :first-child {
      margin-top: 5px;
    }
  }

  .data-block.white-block {
    margin-bottom: 0;

    h3 {
      margin-bottom: 10px;
    }
  }

  .data-block.gray-block {
    background: #F1F3FF;

    h3 {
      margin-bottom: 0;
    }

    .collapsible > :first-child {
      margin-top: 0;
    }
  }

  .data-block.current {
    background: #B2b2b2a1;
  }

  .data-block.potential {
    background: #E2E2E2;
  }

  .data-block.potential, .data-block.current {
    h3, h4, label, .MuiInput-formControl, .MuiInput-input, .MuiTableCell-head, .MuiTableCell-body, .actions-cell .MuiIconButton-root {
      color: inherit;
      background: transparent;
    }

    .MuiInput-input::placeholder {
      color: inherit;
      opacity: 0.5;
    }

    .MuiAutocomplete-clearIndicator, .MuiAutocomplete-popupIndicator, .MuiSelect-icon {
      color: inherit;
    }
  }

  .data-block.standard {
    background: transparent linear-gradient(180deg, #C4C4C480 0%, #7F7F7F80 100%) 0 0 no-repeat padding-box;
  }

  .data-block:first-child {
    margin-top: 15px;
  }

  .data-block:last-child {
    margin-bottom: 15px;
  }

  .indicatorPotentialVisualAid {
    margin-left: -20px;
  }

  .indicatorPotentialVisualAid .infoIcon {
    float: left
  }

  .indicatorPotentialVisualAid .text {
    margin-left: 30px;
    padding-bottom: 10px;
  }
}

.grouping-element-dock h3.grouping-element-name,
.indicator-element-dock h3.indicator-element-name {  

  .MuiTextField-root {
    width: 200px;

    input { 
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
    }
  }

  .MuiIconButton-root { 
    margin-left: 10px;
    vertical-align: middle;
  }
}