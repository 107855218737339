.system-score-table {
  box-sizing: border-box;
  padding: 0;
  display: grid;
  grid-template-columns: 100px 115px 115px 150px;
  grid-gap: 16px 30px;
  align-items: center;
  text-align: right;
  margin-right: 30px;

  &.past {
    grid-template-columns: 200px;
    align-items: flex-start;
    height: 70px;

    .title {
      text-align: left;
    }

    .score {
      text-align: left;
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 47px;
      color: #EB4B42;
    }
  }

  span {
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }

  .left {
    text-align: left;
  }

  .potential {
    color: var(--SECONDARY_BACKGROUND_COLOR);
  }
  .actual {
    color: var(--PRIMARY_BACKGROUND_COLOR);
  }

  .title {
    color: var(--NEUTRAL_TEXT_COLOR);
  }

  .score {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: var(--SECONDARY_TEXT_COLOR);
  }

  .award-case {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 40px;
      height: 40px;
    }

    span {
      text-transform: none;
      max-width: calc(100% - 48px);
      color: var(--SECONDARY_TEXT_COLOR);
    }
  }
}
