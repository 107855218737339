.topicsActionsList {
    margin: 0px;
    width: 100%;

    .topic {
        margin: auto;
        width: 20px;
    }
    .action {
        margin: auto;
    }
    ul {
        padding: 0;
        list-style-type: none;
        margin: 0 0 0 0;
    }
    li {
        width: 100%;
        padding: 3px;
    }
}