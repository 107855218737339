.dashboard-kpi-tab {
  .breadcrumb {
    margin-top: 15px;
    margin-bottom: 16px;
  }

  .kpi-indexes {
    .kpi-index-item {
      margin-right: 16px;
      margin-bottom: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .dashboard-kpi-chart {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;

    .project-gross-floor-area {
      margin-bottom: 30px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    .legend {
      margin-bottom: 9px;
      height: 21px;
      position: relative;

      .sample-line {
        display: inline-block;
        height: 2px;
        width: 20px;
        border-top: 4px solid #62B0FF;
        margin-right: 8px;

        &.potential-line {
          border-color: #548233;
        }

        &.measured-line {
          border-color: #214F91;
        }

        &.estimated-line {
          border-color: #007CC0;
        }

        &.benchmark-line {
          border-color: #F72B2F;
        }
      }
    }

    .muted-legend {
      display: inline-block;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #707070;
    }

    .muted-label {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #B4B4B4;
    }

    .value-dots {
      height: 100%;

      .value-dot-column {
        height: 100%;
        position: relative;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }

        > div {
          opacity: 0;
          transition: opacity 0.2s linear;
        }

        &:hover > div {
          opacity: 1;
        }

        .value-dot-bar-wrapper {
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
        }

        .current-bar {
          position: absolute;
          bottom: 0;
          width: 30%;
          max-width: 30px;
          height: 50%;
        }

        .potential-bar {
          position: absolute;
          bottom: 0;
          width: 30%;
          max-width: 30px;
          height: 60%;
        }

        .translate-left {
          transform: translateX(calc(-50% - 3px));
        }

        .translate-right {
          transform: translateX(calc(50% + 3px));
        }

        .value-dot-bar {
          height: 200px;
          position: absolute;
          bottom: 0;
          width: 100%;
          pointer-events: none;

          .value-dot-line {
            border-left: 2px dashed rgba(0, 41, 101, 0.2);
            left: calc(50% - 1px);
            top: 8px;
            bottom: 0;
            position: absolute;
          }

          .value-dot-wrapper {
            padding: 5px;
            width: 15px;
            height: 15px;
            margin-left: auto;
            margin-right: auto;
            transform: translateY(-13px);
            pointer-events: all;

            .value-dot {
              width: 15px;
              height: 15px;
              background: #62B0FF;
              border: 2px solid #FFFFFF;
              border-radius: 50%;
              box-sizing: border-box;
              box-shadow: 0 2px 2px rgba(0, 0, 0, 0.161);
              pointer-events: none;
            }
          }

        }
      }
    }

    .top-part {
      .left-part {
        h2 {
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          color: #002965;
          margin-top: 20px;
          margin-bottom: 30px;
        }
      }

      .right-part {
        padding-top: 20px;
      }
    }

    .middle-part .right-part {
      width: 200px;
      padding-left: 20px;
      margin-top: -10px;

      h3 {
        margin-top: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
    }
  }
}
