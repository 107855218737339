.actions-filter.filter-blocks {

  .filter-block.all {
    .line {
      border-color: var(--RED_COLOR);
      background: var(--RED_COLOR);
    }

    &.active {
      background: var(--PRIMARY_TEXT_COLOR);

      h3, .amount {
        color: #000000;
      }

      .line {
        border-color: var(--RED_COLOR) !important;
        background: var(--RED_COLOR) !important;
      }
    }
  }

  .filter-block.ENABLED {
    .line {
      border-color: var(--GREEN_COLOR);
      background: var(--GREEN_COLOR);
    }

    &.active {
      background: var(--GREEN_COLOR);
    }
  }

  .filter-block.DISABLED  {
    .line {
      border-color: var(--NEUTRAL_TEXT_COLOR);
      background: var(--NEUTRAL_TEXT_COLOR);
    }

    &.active {
      background: var(--NEUTRAL_TEXT_COLOR);
    }
  }

  .filter-block.WAITING {
    .line {
      border-color: var(--ORANGE_COLOR);
      background: var(--ORANGE_COLOR);
    }

    &.active {
      background: var(--ORANGE_COLOR);
    }
  }

}
