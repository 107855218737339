.MuiSelect-select:focus,
.MuiSelect-select:active {
  background: none !important;
}

.status-badge {
  display: inline-block;
  line-height: 19px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0;

  &.status-IN_PROGRESS,
  &.status-IN_PROCESSING,
  &.status-SCREENING {
    color: var(--ORANGE_COLOR);
    -webkit-text-fill-color: var(--ORANGE_COLOR);
  }

  &.status-NOT_APPLICABLE {
    color: #960000;
    -webkit-text-fill-color: #960000;
  }

  &.status-VERIFICATION,
  &.status-RATED {
    color: var(--BLUE_COLOR);
    -webkit-text-fill-color: var(--BLUE_COLOR);
  }

  &.status-CLOSED,
  &.status-APPROVED,
  &.status-RELEASED,
  &.status-REPORTING {
    color: var(--GREEN_COLOR);
    -webkit-text-fill-color: var(--GREEN_COLOR);
  }

  &.status-LOCKED,
  &.status-NOT_RATED {
    color: #585858;
    -webkit-text-fill-color: #585858;
  }
}
