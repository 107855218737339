.project-custom-fields {
  .checkbox-field-label {
    display: block;
    padding-top: 16px;
    margin-bottom: 8px;
  }

  .MuiCheckbox-root {
    padding: 4px;
    margin-right: 4px;
  }
}
