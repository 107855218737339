.kpi-system-base-data {
  padding: 0 45px;

  .collapse-trigger {
    svg {
      transform: scale(1.5);
      color: #000000;
    }
  }

  .collapsible {
    margin-top: 0;
    padding: 0 5px;

    .collapsible-inner {
      margin-bottom: 26px;
    }
  }

  .collapsible-inner > div {
    margin-left: 26px;

    &:first-child {
      margin-left: 0;
    }
  }

  h2 {
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    text-transform: uppercase;
    padding: 0 0 0 5px;
    margin: 0;
  }

  .name-col {
    flex: 1;
  }

  .description-col {
    flex: 3;
  }

  .project-type-col {
    width: 320px;
  }

  .MuiFormHelperText-root.Mui-error {
    transform: translateY(20px);
  }

  .MuiInputBase-input.Mui-disabled {
    color: #000000DE;
  }
}
