.projects-page.page-with-table {
  .page-header > .flex-col:first-child,
  .page-content > .flex-col:first-child {
    width: 305px;
    min-width: 305px;
  }

  .action-cell {
    min-width: 175px;
    width: 175px;

    .MuiSvgIcon-colorAction {
      color: #02c69a;
    }
  }

  .name-column {
    min-width: 200px;
  }

  .system-score-column {
    min-width: 200px;
  }

  .with-right-border {
    border-right: 2px dashed var(--NEUTRAL_TEXT_COLOR);
  }

  .search-restricted {
    margin-left: 20px;

    .MuiFormControl-root.MuiFormControl-fullWidth {
      margin-top: 10px;
    }
  }
}

#new-projects-table {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  tbody tr.k-table-row {
    height: 50px;
  }

  th:not([aria-label="Filter"]) {
    border: none !important;
    border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
    font-size: 16px;
  }

  th,
  td {
    padding: 0 !important;
    margin: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
    background-color: white !important;
    padding-left: 12px !important;

    .k-cell-inner {
      padding: 0 !important;
      margin: 0 !important;

      .k-link {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }

  .filter-padding {
    padding: 5px;
  }

  .k-filtercell-operator {
    align-self: flex-end;

    .k-dropdownlist {
      background-color: transparent !important;
    }

    .k-dropdown-operator.k-picker-md .k-input-button {
      padding: 0;
    }
  }

  .MuiSwitch-switchBase.Mui-checked {
    color: var(--PRIMARY_BACKGROUND_COLOR) !important;
  }

  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: var(--PRIMARY_BACKGROUND_COLOR) !important;
  }

  .show-filter {
    color: var(--PRIMARY_BACKGROUND_COLOR);
  }

  .hide-filter {
    color: gray;
  }

  .flex-left {
    text-align: right;
    padding-right: 8px;
    flex: auto;
  }

  .flex-left-name-column {
    text-align: left;
    padding-right: 25px;
  }

  .flex-right {
    text-align: right;
    min-width: 64px;
  }

  .k-cell-inner {
    text-transform: uppercase;

    .k-link {
      justify-content: center;
    }
  }

  .name-column-template-collapse .k-icon {
    cursor: pointer;
  }
}
