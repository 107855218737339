.kpi-control {
  .kpi-field {
    flex: auto;
  }

  .estimation-field {
    min-width: 110px;
    padding-left: 16px;
  }
}
