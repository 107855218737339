.rating-system-page {
  .data-block.current {
    background-color: var(--PRIMARY_BACKGROUND_COLOR) !important;
    color: var(--PRIMARY_TEXT_COLOR) !important;

    .MuiInputBase-root {
      background-color: var(--PRIMARY_BACKGROUND_COLOR) !important;
      color: var(--PRIMARY_TEXT_COLOR) !important;
    }
  }

  .data-block.potential {
    background-color: var(--SECONDARY_BACKGROUND_COLOR) !important;
    color: var(--SECONDARY_TEXT_COLOR) !important;

    .MuiInputBase-root {
      background-color: var(--SECONDARY_BACKGROUND_COLOR) !important;
      color: var(--SECONDARY_TEXT_COLOR) !important;
    }
  }

  .page-header {
    margin-left: 0;
    margin-right: 0;

    .page-title {
      font-weight: bold;
      font-size: 28px;
      line-height: 37px;
      letter-spacing: 0;
      color: #000000;
      padding: 0;
      margin: 0 10px 0 0;
    }

    .MuiFormHelperText-root.Mui-error {
      text-align: center;
      transform: translateY(20px);
    }
  }

  .content-panel {
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0 50px 0 50px;

    .content-block {
      background: var(--PRIMARY_TEXT_COLOR);
      box-shadow: 0 2px 2px #00000029;
      border: 1px solid #efefef;
      padding: 30px;
      margin-bottom: 30px;
    }

    .top-panel {
      flex: 1;
      flex-grow: 0;
    }

    .dock {
      flex: auto;

      .wrapper-panel {
        padding-right: 0;
      }

      .image-blocks {
        grid-template-columns: repeat(auto-fill, 160px);
        grid-gap: 20px;
        margin-bottom: 20px;

        .image-block {
          height: auto;
          max-width: 160px;
          max-height: 160px;
        }
      }

      &.dock-open {
        .wrapper-panel {
          padding-right: 1px;
        }
      }
    }
  }

  .total-score {
    font-size: 20px;
    line-height: 26px;
    padding: 0 5px;
  }

  .award-col {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .award-title {
      font-size: 16px;
      line-height: 38px;
      text-transform: uppercase;
    }

    .total-score {
      font-size: 16px;
      line-height: 38px;
    }

    &.current .award-title {
      color: #073a5f;
    }

    &.potential .award-title {
      color: #006737;
    }
  }

  .award {
    width: 50px;
    height: 50px;
    display: inline-block;
    margin: 0 20px;
    transform: translateY(-25%);

    .award-image-wrapper {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50px;
      height: 50px;

      .award-image {
        max-width: 50px;
        max-height: 50px;
      }
    }
  }

  .award-label {
    display: inline-block;
    letter-spacing: 0.8px;
    color: #575756;
    text-transform: uppercase;
    padding: 0 5px;
    font-size: 16px;
    line-height: 38px;
  }

  .base-data .validation-failed {
    display: inline-block;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.8px;
    color: var(--RED_COLOR);
    padding: 0 5px;
  }

  .collapse-trigger {
    svg {
      transform: scale(1.5);
      color: #000000;
    }
  }

  .collapsible {
    margin-top: 0;
    padding: 0 5px;

    .collapsible-inner {
      margin-bottom: 26px;
    }
  }

  .collapsible-inner > div {
    margin-left: 26px;

    &:first-child {
      margin-left: 0;
    }
  }

  .base-data {
    margin: 0 -5px 0 -5px;

    h2 {
      font-size: 20px;
      line-height: 26px;
      color: #000000;
      text-transform: uppercase;
      padding: 0 0 0 5px;
      margin: 0;
    }

    .changed fieldset.MuiOutlinedInput-notchedOutline {
      background-color: #f8f5b3;
    }

    .description-col {
      flex: 4;
    }

    .project-type-col {
      width: 320px;
    }

    .status-col {
      max-width: 236px;
      flex: 1;
    }

    .MuiFormHelperText-root.Mui-error {
      transform: translateY(20px);
    }

    .MuiInputBase-input.Mui-disabled {
      color: #000000de;
    }
  }

  .data-block .collapsible-header .MuiIconButton-root {
    margin-left: 8px;
    transform: translateY(-3px);
  }

  .actions-block {
    ul {
      padding: 0;

      li {
        font-size: 16px;
        line-height: 21px;
        margin-top: 15px;
        color: #000000;
        list-style: none;

        &:before {
          content: "·";
          font-size: 100px;
          vertical-align: middle;
          line-height: 21px;
          color: #1c304d;
        }
      }
    }
  }

  .fabs {
    svg {
      color: var(--PRIMARY_TEXT_COLOR);
    }
  }

  .goals-wrapper {
    overflow: hidden;
    overflow-y: auto;
  }
}
