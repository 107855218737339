.project-group-analytics {
  width: 450px;
  min-width: 450px;

  .loading-overlay {
    margin: -20px -30px;
  }

  .simple-table-sort {
    margin-bottom: 0;

    .number-column {
      text-align: right;
      width: 1%;
    }

    .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root {
      border-bottom: none;
    }

    .MuiTableContainer-root .MuiTableRow-head .MuiTableCell-head {
      background: #ECECEC;
    }

    .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:last-child {
      padding-right: 24px;
    }
  }

  .search-box {
    margin: 0;
    padding: 20px 30px;
  }
}
