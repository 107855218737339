.kpi-system-grouping {
  padding: 0 50px 0 50px;
  height: 100%;
  overflow: hidden;

  .MuiTableRow-root {
    border-bottom: 1px solid white;
  }

  .MuiTableCell-root {
    &:nth-child(3) {
      box-shadow: none;

      .column-header {
        margin-right: 0;
      }
    }
  }

  .MuiTableContainer-root.advanced-table .MuiTableHead-root .MuiTableCell-head {
    height: auto;
  }

  .cell-inner {
    min-height: 40px;
  }

  .kpi-column .cell-inner {
    display: flex;

    .kpi-name {
      line-height: 40px;
    }

    .start-adornment {
      padding-top: 5px;
    }

    .end-adornment {
      padding-top: 7px;
      padding-left: 5px;
    }
  }

  .sustainability-row-CATEGORY, .sustainability-row-CATEGORY .MuiTableCell-root {
    background-color: #D5DBE8;
  }

  .sustainability-row-CRITERION, .sustainability-row-CRITERION .MuiTableCell-root {
    background-color: #EBEFFB;
  }

  .sustainability-row-KPI, .sustainability-row-KPI .MuiTableCell-root {
    background-color: #F1F3FF;
  }

  .MuiFormControl-root.MuiTextField-root.marked {
    background-color: rgba(#E8DF00, 0.3);
  }
}
