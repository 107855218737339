.actions-filter.filter-blocks {
  .filter-block.ENABLED, .filter-block.ENABLED {
    .line {
      border-color: var(--GREEN_COLOR);
      background: var(--GREEN_COLOR);
    }

    &.active {
      background: var(--GREEN_COLOR);
    }
  }

  .filter-block.DISABLED, .filter-block.DISABLED {
    .line {
      border-color: var(--NEUTRAL_TEXT_COLOR);
      background: var(--NEUTRAL_TEXT_COLOR);
    }

    &.active {
      background: var(--NEUTRAL_TEXT_COLOR);
    }
  }
}
