.side-menu-show {
  left: -50px;
  top: -25px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  background-color: var(--PRIMARY_BACKGROUND_COLOR);
  color: var(--PRIMARY_TEXT_COLOR) !important;
  z-index: 999;
  opacity: 1;
  transition: opacity 0.35s ease-out, left 0.35s ease-out;

  &:hover {
    background-color: var(--PRIMARY_BACKGROUND_COLOR);
    color: var(--PRIMARY_TEXT_COLOR);
    left: -175px;
  }

  &.fade-hide {
    opacity: 0;
  }

  &.hide {
    display: none;
  }
}

#new-rating-system-side-menu {
  &:not(.resizing) {
    transition: width 0.35s ease-out;
  }

  .side-menu-content {
    background-color: white;
    width: 100%;
    .side-menu-header {
      font-size: 20px;
    }
  }

  .kpi-warning {
    color: var(--ORANGE_COLOR);
  }

  .system-score-range {
    justify-content: end;
  }
}

.resizer.resizing,
.resizer:hover {
  cursor: ew-resize;

  .resize-bar {
    border: 2px solid var(--SECONDARY_BACKGROUND_COLOR);
  }
}

.no-element-selected-icon {
  font-size: 150px;
  color: lightgray;
  align-self: center;
}

.no-element-selected-info {
  font-size: 16px;
  color: gray;
  align-self: center;
}

.fade-in-section {
  opacity: 0;
  transition: opacity 0.35s ease-out;
  will-change: opacity;

  &.active {
    opacity: 1;
  }
}

.element-data-container {
  #metricsBlock {
    background-color: #f1f3ff;

    .evaluation-standard-file {
      background-color: var(--SECONDARY_BACKGROUND_COLOR) !important;
      color: var(--SECONDARY_TEXT_COLOR) !important;
    }
  }

  #actualBlock {
    background-color: var(--PRIMARY_BACKGROUND_COLOR);
    color: var(--PRIMARY_TEXT_COLOR);

    .MuiAccordionSummary-expandIconWrapper {
      color: var(--PRIMARY_TEXT_COLOR);
    }

    .floating-inputs .k-input,
    .floating-inputs .k-label,
    .MuiButtonBase-root,
    .floating-inputs .k-picker {
      border-color: rgb(165, 165, 165);
      color: var(--PRIMARY_TEXT_COLOR);
    }
  }

  #potentialBlock {
    background-color: var(--SECONDARY_BACKGROUND_COLOR);
    color: var(--SECONDARY_TEXT_COLOR);

    .floating-inputs .k-label {
      color: var(--SECONDARY_TEXT_COLOR);
    }
  }

  #actionsBlock {
    background-color: transparent;
  }

  #responsibilityBlock {
    background-color: transparent;
  }

  .element-data-header {
    font-size: 18px;
  }

  .MuiChip-root {
    background-color: #efefef;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .MuiChip-clickable:hover,
  .MuiChip-clickable:focus {
    background-color: rgb(206, 206, 206);
  }
}

.new-rating-system-side-menu-operations {
  background-color: white;

  &:hover {
    background-color: white;
  }
}
