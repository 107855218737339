.image-blocks {
  display: grid;
  grid-gap: 45px;
  grid-template-columns: repeat(auto-fill, 250px);
  margin-bottom: 30px;

  .image-block {
    justify-self: center;
    width: 250px;
    height: 156px;
    background-color: var(--PRIMARY_TEXT_COLOR);
    border: 1px solid var(--NEUTRAL_TEXT_COLOR);
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    position: relative;
    padding: 2px;
    box-sizing: border-box;

    &:hover {
      cursor: pointer;

      img {
        opacity: 0.7;
      }

      .MuiFab-root {
        opacity: 1;
        pointer-events: all;
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      object-fit: contain;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }

    .MuiFab-root {
      position: absolute;
      bottom: 10px;
      right: 10px;
      margin: 0;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease-in-out;
    }
  }
}
