.actions-table {
  .waiting-button {
    display: inline-block;
    white-space: nowrap;
    text-transform: uppercase;
    margin-top: 15px;
    padding: 0;

    .MuiButton-label {
      font-size: 16px;
      line-height: 21px;
      font-weight: bold;
    }

    &.MuiButton-textPrimary {
      color: var(--GREEN_COLOR);
      margin-left: auto;
    }

    &.MuiButton-textSecondary {
      color: var(--RED_COLOR);
    }

    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
    }

    svg {
      margin-right: 5px;
      margin-bottom: -5px;
      width: 21px;
      height: 21px;
    }
  }

  .indicator-actions-table-footer {
    display: inline-block;
    position: relative;

    &:hover .add-button,
    .Mui-focused .add-button {
      display: block;
    }

    .MuiAutocomplete-root {
      display: inline-block;
    }

    .add-button {
      display: none;
      position: absolute;
      right: 26px;
      top: 4px;
    }
  }
}

.MuiAutocomplete-popper {
  z-index: 9999;
}
