.MuiDialog-root.benchmarks-dialog {

  .loading-overlay {
    overflow: visible;
  }

  .MuiDialog-paper {
    overflow: hidden;
    width: 640px;

    .dialog-body {
      margin: 0;
    }

    .simple-table-sort {
      min-height: 200px;
    }

    .modal-table {
      overflow: visible;
    }

    .MuiTableBody-root .MuiTableRow-root {
      cursor: pointer;

      &:hover {
        background-color: var(--PRIMARY_BACKGROUND_LOW_OPACITY_COLOR);
      }
    }
  }

}
