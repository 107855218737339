.custom-dialogs .custom-dialog .child-project-groups-dialog {

  h2 {
    margin-bottom: 20px;
  }

  .search-box {
    height: 70px;
    box-sizing: border-box;
    border-top: 1px solid #D0D0D0;
    margin: 0 -30px 0 -30px;
    padding: 23px 30px;
    position: relative;
  }

  .simple-table-sort {
    .MuiTableContainer-root {
      margin-bottom: -24px;
    }
  }

  .action-header-cell {
    width: 177px;
    text-align: right;
  }

}
