.tenants-table {
  .enabled {
    text-transform: uppercase;
    color: var(--GREEN_COLOR);
    font-weight: bold;
  }

  .disabled, .default {
    text-transform: uppercase;
    color: var(--NEUTRAL_TEXT_COLOR);
    font-weight: bold;
  }

  .MuiFormGroup-root {
    .MuiTypography-root {
      white-space: nowrap;
      line-height: 32px;
    }

    .MuiIconButton-root {
      padding: 0;
      margin-right: 5px;
    }
  }

  .MuiButtonBase-root {
    float: right;
  }
}
