
.system-score-progress {
  width: 100%;
  padding: 0;
  margin-left: auto;
  overflow: hidden;

  &.past {
    .progress-bar .awards:before {
      content: '1';
    }

    .progress-bar .awards {
      background: linear-gradient(90deg, #EB4B42 0%, #F5C242 28.9%, #FCEF4F 50.96%, #9FCE63 71.06%, #48A064 100%);
    }

    .segment {
      width: 25%;
      border-right: 2px solid white;
      height: 100%;
      display: inline-block;
      box-sizing: border-box;
    }
  }

  .title {
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: var(--NEUTRAL_TEXT_COLOR);
  }

  .progress-bar {
    width: 100%;
    margin-top: 20px;

    & > section {
      position: relative;
      width: 100%;
      height: 25px;
    }

    .scores {
      margin-bottom: 5px;
      z-index: 2;
      background: var(--NEUTRAL_BACKGROUND_COLOR);

      svg {
        width: 14px;
        height: 28px;
        position: absolute;
        top: 100%;
        left: 0;
      }

      .score {
        box-sizing: border-box;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 16px;
        line-height: 25px;
        text-align: right;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: 2px;
          height: 55px;
          background: var(--PRIMARY_BACKGROUND_COLOR);
        }

        &.potential {
          color: var(--SECONDARY_TEXT_COLOR);
          background: var(--SECONDARY_BACKGROUND_COLOR);
        }

        &.negative-potential {
          color: var(--PRIMARY_TEXT_COLOR);
          background: var(--RED_COLOR);
        }

        &.actual {
          color: var(--PRIMARY_TEXT_COLOR);
          background: var(--PRIMARY_BACKGROUND_COLOR);
        }

        span {
          padding: 0 6px;
        }
      }
    }

    .awards {
      position: relative;
      background: linear-gradient(90deg, var(--PRIMARY_TEXT_COLOR) 0%, var(--PRIMARY_BACKGROUND_COLOR) 100%);
      margin-bottom: 35px;

      &:after, &:before, .award-case span {
        position: absolute;
        top: calc(100% + 16px);
        font-size: 16px;
        line-height: 19px;
        color: rgba(28, 48, 77, 0.5);
        text-transform: uppercase;
      }

      &:before {
        content: '0';
        left: 0;
      }

      &:after {
        content: attr(data-max);
        right: 0;
      }

      .award-case {
        position: absolute;
        top: 0;
        height: 100%;

        &:last-child .name {
          max-width: calc(100% - 80px);
        }

        &:after {
          content: '';
          position: absolute;
          right: 100%;
          top: 0;
          width: 2px;
          height: 100%;
          background: #EFEFEF;
        }

        .from {
          left: 0;
          transform: translateX(-50%);
        }

        .name {
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          display: inline-block;
          max-width: calc(100% - 40px);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
