.dashboard-timeline {
  margin-bottom: 65px;

  h2 {
    font-size: 30px;
    line-height: 39px;
    font-weight: bold;
    color: #1C304D;
    margin-bottom: 40px;
    margin-top: 0;

    svg {
      margin-right: 12px;
      width: 36px;
      height: 36px;
      transform: translateY(7px);
    }
  }

  .timeline-items {
    display: flex;
    justify-content: flex-end;
    border-bottom: 3px solid #1C304D33;
    height: 0;

    .timeline-item {
      text-align: center;
      flex: 1;
      display: flex;

      &:first-child {
        text-align: left;
        flex: 0.5;

        .timeline-text {
          right: auto;
          left: 0;
        }
      }

      &:last-child {
        text-align: right;
        flex: 0.5;

        .timeline-text {
          right: 0;
        }
      }

      .timeline-dot {
        display: inline-block;
        width: 100%;
        position: relative;

        &.active {
          .timeline-circle {
            border-width: 5px;
          }
        }

        &:hover {
          z-index: 1;

          .timeline-text {
            background-color: #F2F4F7;
          }
        }

        &:hover, &.active {
          cursor: pointer;

          .timeline-circle {
            width: 50px;
            height: 50px;
            margin-top: -25px;
          }

          .timeline-text {
            opacity: 1;
          }
        }

        &.status-REPORTING {
          .timeline-circle {
            background: #86BA97;
            border-color: var(--GREEN_COLOR);
          }

          .timeline-text {
            color: #007C05CC;
          }
        }

        &.status-VERIFICATION {
          .timeline-circle {
            background: #79A9D9;
            border-color: var(--BLUE_COLOR);
          }

          .timeline-text {
            color: #005EBCCC;
          }
        }

        &.status-SCREENING {
          .timeline-circle {
            background: #EFAB7B;
            border-color: var(--ORANGE_COLOR);
          }

          .timeline-text {
            color: #EB6200CC;
          }
        }
      }
    }

    &.one-item .timeline-item:first-child {
      text-align: right;

      .timeline-text {
        right: 0;
      }
    }

    &.too-many {
      .timeline-item:first-child, .timeline-item:last-child {
        flex: 1;
      }
    }

    .timeline-circle {
      box-sizing: border-box;
      background: #79A9D9;
      border-radius: 50%;
      border-width: 0;
      border-style: solid;
      width: 30px;
      height: 30px;
      margin-top: -15px;
      display: inline-block;
      transition: width 0.2s ease-in-out, height 0.2s ease-in-out, margin-top 0.2s ease-in-out, border-width 0.2s ease-in-out;
    }

    .timeline-text {
      pointer-events: none;
      font-size: 18px;
      line-height: 20px;
      font-weight: bold;
      white-space: nowrap;
      display: inline-block;
      letter-spacing: 0.32px;
      opacity: 0;
      position: absolute;
      top: 35px;
      right: calc(50% - 48px);
      transition: opacity 0.2s ease-in-out;
    }
  }
}

.tooltip-popover.dashboard-timeline-tooltip-popover {
  transform: translateY(-30px);

  .MuiPopover-paper {
    background: #002965;
    border-radius: 5px;
    padding: 8px 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .muted {
    opacity: 0.5;
  }
}
