.versions-dialog {
  .MuiDialog-paper {
    padding: 0;
    min-width: 800px;
    box-sizing: border-box;

    .MuiDialogTitle-root {
      text-align: center;
      padding: 30px;
      font-weight: 500;
      font-size: 24px;
      letter-spacing: 0.43px;
    }

    .MuiDialogContent-root {
      padding: 0;
    }

    .MuiDialogActions-root {
      padding: 30px;

      .MuiButton-root {
        color: #505050;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        padding: 0;
      }
    }
  }

  &-loader {
    width: 100%;
    min-height: auto;
  }
}
