.indicator-actions-dialog {
  .dialog-body {
    margin: 10px 0;
  }

  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 55px;
  }

  .MuiTableContainer-root {
    min-height: 500px;
  }
  .simple-table-sort {
    .MuiTableContainer-root.actions-table {
      .MuiTableHead-root .MuiTableCell-head {
        white-space: normal;
        vertical-align: bottom;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.8px;
      }

      .name-column {
        min-width: 200px;
      }

      .description-column {
        min-width: 200px;
      }

      .type-column {
        min-width: 200px;
      }

      .default-column {
        min-width: 180px;
        max-width: 180px;
      }

      .responsible-column {
        min-width: 200px;
        max-width: 200px;
      }

      .date-column {
        min-width: 155px;
        max-width: 155px;
      }

      .actions-column {
        width: 100px;
        text-align: right;
      }
    }
  }

  .body-footer-row {
    .MuiSvgIcon-root {
      font-size: 18px;
    }
    .MuiAutocomplete-root {
      width: 268px;
    }
  }
}
