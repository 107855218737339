.dashboard-tabs {
  margin-left: 50px;

  &.unfolded {
    margin-left: 0;

    .grouping-bar-charts {
      padding-left: 50px;
    }
  }


  .capitalized * {
    text-transform: capitalize !important;
  }

  .tabs-top-right-buttons {
    position: absolute;
    right: 0;
    top: 8px;

    .expand-collapse {
      transform: rotate(90deg);
    }
      
    .export-excel { 
      margin-right: 10px;   
      background: #E5EAF2;
      padding: 5px 6px;
      color: var(--PRIMARY_BACKGROUND_COLOR) !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: none;
    }
  }

}
