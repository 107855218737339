#new-esg-rating-system-table {
  .k-table .k-table-row .k-table-th.k-grid-header-sticky .k-cell-inner {
    background-color: white !important;
  }

  .k-filtercell.primary {
    background-color: var(--PRIMARY_BACKGROUND_COLOR) !important;
    color: var(--PRIMARY_TEXT_COLOR);
    border-color: var(--PRIMARY_BACKGROUND_COLOR) !important;
    border-left-color: var(--PRIMARY_BACKGROUND_COLOR) !important;

    .k-input-flat {
      border-color: rgb(165, 165, 165);
      color: var(--PRIMARY_TEXT_COLOR);
    }

    .k-picker-solid {
      border-color: rgb(165, 165, 165);
    }

    .k-dropdownlist {
      color: whitesmoke !important;
    }
  }

  .k-filtercell.secondary {
    background-color: var(--SECONDARY_BACKGROUND_COLOR) !important;
    color: var(--SECONDARY_TEXT_COLOR);
    border-color: var(--SECONDARY_BACKGROUND_COLOR) !important;
    border-left-color: var(--SECONDARY_BACKGROUND_COLOR) !important;

    .k-input {
      color: var(--SECONDARY_TEXT_COLOR);
    }
  }

  .k-table {
    th,
    td {
      border: none !important;
      padding: 0 !important;

      &.numeric-td {
        text-align: right;

        &.error {
          color: var(--RED_COLOR) !important;
        }
      }
    }

    td {
      border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;

      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }

  .k-filter-row {
    .k-table-th {
      height: 48px;
    }
  }

  tr[aria-rowindex="1"] {
    .k-table-th {
      height: 106px;

      .k-cell-inner {
        border-bottom: none !important;
      }
    }
  }

  .k-table-th:not(:first-child) {
    .k-cell-inner {
      border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
  }

  .k-table-th {
    .k-cell-inner {
      margin: 0 !important;
      height: 100%;

      &.k-filtercell {
        .k-multiselect .k-clear-value {
          display: none;
        }
      }

      .k-filtercell-operator {
        align-self: flex-end;

        .k-dropdownlist {
          background-color: transparent !important;
        }

        .k-dropdown-operator.k-picker-md .k-input-button {
          padding: 0;
        }
      }

      .filter-padding {
        padding: 5px;
      }

      .k-link {
        justify-content: center;
        padding: 5px !important;

        .k-column-title {
          white-space: pre-wrap;
          text-align: center;
        }
      }
    }

    .filler-th {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &.parent {
      .k-link {
        padding: 0 !important;
        font-size: 10px;
      }
    }

    &.primary {
      background-color: var(--PRIMARY_BACKGROUND_COLOR) !important;
      color: var(--PRIMARY_TEXT_COLOR);
      border-color: var(--PRIMARY_BACKGROUND_COLOR) !important;
      border-left-color: var(--PRIMARY_BACKGROUND_COLOR) !important;

      .k-link:hover {
        color: var(--PRIMARY_TEXT_COLOR);
      }
    }

    &.secondary {
      background-color: var(--SECONDARY_BACKGROUND_COLOR) !important;
      color: var(--SECONDARY_TEXT_COLOR);
      border-color: var(--SECONDARY_BACKGROUND_COLOR) !important;
      border-left-color: var(--SECONDARY_BACKGROUND_COLOR) !important;

      .k-link:hover {
        color: var(--SECONDARY_TEXT_COLOR);
      }
    }
  }

  .category-row {
    background-color: #d5dbe8 !important;

    &.k-selected {
      background-color: #99b3ff !important;

      .k-grid-content-sticky {
        background-color: #99b3ff !important;
      }
    }

    .k-grid-content-sticky {
      background-color: #d5dbe8 !important;
      border-left: none !important;
      border-right: none !important;
    }
  }

  .criterion-row {
    background-color: #ebeffb !important;

    &.k-selected {
      background-color: #99b3ff !important;

      .k-grid-content-sticky {
        background-color: #99b3ff !important;
      }
    }

    .k-grid-content-sticky {
      background-color: #ebeffb !important;
      border-left: none !important;
      border-right: none !important;
    }
  }

  .indicator-row {
    background-color: #f1f3ff !important;

    &.k-selected {
      background-color: #99b3ff !important;

      .k-grid-content-sticky {
        background-color: #99b3ff !important;
      }
    }

    .k-grid-content-sticky {
      background-color: #f1f3ff !important;
      border-left: none !important;
      border-right: none !important;
    }
  }

  .k-table-row {
    .excluded-cell {
      filter: blur(2px);
    }
  }
}

.hide-column-menu {
  .root {
    margin-left: 15px;
  }

  .nested {
    margin-left: 25px;
  }
}

.status-dropdown .k-input-inner {
  padding: 0;
  justify-content: center;
}

.header-button {
  background: #e5eaf2;
  padding: 9px 10px;
  color: var(--PRIMARY_BACKGROUND_COLOR) !important;
  text-transform: uppercase;
  margin-left: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  svg {
    margin-right: 8px;
    fill: var(--PRIMARY_BACKGROUND_COLOR) !important;
    width: 16px;
    height: 16px;
  }

  &a svg {
    transform: translateY(3px);
  }

  &.Mui-disabled {
    opacity: 0.5;
  }
}

#new-rating-system-esg-header {
  .system-source {
    font-size: 28px;
  }

  .progress-bar-flex {
    flex: 3;
  }

  .ko-message {
    transition: opacity 0.15s ease-out;
    will-change: opacity;
    background: var(--ORANGE_BACKGROUND_COLOR);
    color: var(--ORANGE_COLOR);

    hr {
      border: none;
      border-top: 1px solid var(--ORANGE_COLOR);
    }
  }

  .header-primary-background-text {
    color: var(--PRIMARY_BACKGROUND_COLOR);
  }

  .header-secondary-background-text {
    color: var(--SECONDARY_BACKGROUND_COLOR);
  }

  .show-master-data-arrow {
    transition: transform 0.15s ease-out;
  }
}

#new-rating-system-fab-buttons {
  .save-button,
  .edit-button {
    background: var(--PRIMARY_BACKGROUND_COLOR);
    color: var(--PRIMARY_TEXT_COLOR);
  }

  .close-button {
    background: var(--SECONDARY_BACKGROUND_COLOR);
    color: var(--PRIMARY_TEXT_COLOR);
  }
}

#new-esg-rating-system-table-options {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  .MuiSwitch-switchBase.Mui-checked {
    color: var(--PRIMARY_BACKGROUND_COLOR) !important;
  }

  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: var(--PRIMARY_BACKGROUND_COLOR) !important;
  }

  .show-filter {
    color: var(--PRIMARY_BACKGROUND_COLOR);
  }

  .hide-filter {
    color: gray;
  }
}

.multi-select-filter-popup {
  .k-selected {
    background-color: rgb(222, 222, 222) !important;
  }
}

.ignored-value {
  color: rgba(0, 0, 0, 0.3);
}
