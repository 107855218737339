.indicator-data-dialog {
  .unit {
    display: inline-flex
  }

  .selectMargin {
    margin-top: -10px;
    width: 175px;
  }

  .dialog-body {
    margin: 10px 0;

    .links-list, .files-list {
      margin-bottom: 20px;
    }

    h2.links-header, h2.files-header {
      .MuiIconButton-sizeSmall {
        padding: 0;
      }

      margin-bottom: 8px;
    }

    .indicator-value-input {
      margin-top: 16px;
    }

    .value-type {
      padding-bottom: 10px;

      .MuiRadio-root {
        padding: 8px;
        margin-right: 8px;
      }

      label {
        cursor: pointer;

        .label {
          transform: translateY(2px);
          display: inline-block;
        }
      }

      button {
        margin-left: 8px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .evaluation-standard textarea {
      width: 480px;
      min-height: 160px;
    }

    pre {
      white-space: break-spaces;
    }

    p.Mui-error {
      padding-left: 0;
    }
  }
}
