.users-table {

  .user-column {
    width: 0;
  }

  .user-avatar {
    margin-left: 0;
    margin-right: 0;
  }

  .username-column {
    width: 20%;
  }

  .email-column {
    width: 20%;
  }

  .status-column {
    width: auto;
  }

  .roles-column {
    width: auto;
  }

  .tenant-column {
    width: auto;
  }

  .actions-column {
    width: 0;
  }

  .user-status-ENABLED {
    color: var(--GREEN_COLOR) !important;
    font-weight: bold;
  }

  .user-status-DISABLED {
    color: var(--NEUTRAL_TEXT_COLOR) !important;
    font-weight: bold
  }
}
