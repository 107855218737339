.project-attribute-drag-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  cursor: grabbing;

  .ghost {
    position: absolute;
    opacity: 0.75;
    pointer-events: none;
    transform: translateX(-25px) translateY(-40px);
  }

  .landing {
    position: absolute;
    pointer-events: none;
    outline: 1px solid var(--PRIMARY_BACKGROUND_COLOR);
    border-radius: 10px;
    opacity: 0.25;

    &.active {
      background-color: var(--SECONDARY_BACKGROUND_COLOR);
    }
  }
}
