.project-group-analyze-page {
  .top-block {
    width: 100%;
    display: flex;
  }

  .right-block {
    position: relative;
    padding: 20px 0 0 0;


    .white-gradient {
      pointer-events: none;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: 75px;
      height: 80px;
      background: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    }

    .content-block-inner {
      padding: 0 30px;
    }

    .project-group-hierarchy {
      margin: 0 -30px;
      padding-bottom: 20px;
    }

    .collapse-button {
      position: absolute;
      right: 20px;
      top: 25px;
      z-index: 1;
    }
  }

  .main-block {
    min-width: 520px;
    width: 520px;
    margin-right: 20px;
  }

  .content-block-component {
    margin-bottom: 20px;
    overflow: hidden;

    .content-block-inner {
      height: 100%;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #1C304D;
    margin: 0 0 20px 0;
  }

  .projects-title {
    .items-amount {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #707070;
      margin-top: 13px;
      margin-left: 16px;
    }
  }
  
  .projectGroupsMapWrapper .content-block-component .content-block-inner {
    padding: 0px;
  }

  .projectGroupsMap .content-block-inner {
    display: grid;
    grid-template-columns: 100% 0;
  }

  .card-visible {
    display: grid;
    grid-template-columns: calc(75%) 25%;
    grid-gap: 0px;
  }
  .full-name {
      margin-left: 50px;
      font-size: 28px;
      line-height: 23px;
      color: #1C304D;
      font-weight: bold;
  }
  .company {
      margin-left: 50px;
      font-size: 20px;
      color: darkgray;
  }

  .row {
      display: table;

  }
  .cell {
      white-space: nowrap;
      display: table-cell;
      vertical-align: middle;
      text-align:left;
  }
}
