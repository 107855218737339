.system-detail-dialog {
  .modal-table.MuiTable-root {
    margin-bottom: 16px;

    .MuiTableBody-root {
      .MuiTableCell-body {
        &:last-child {
          padding-right: 21px;
        }
      }
    }
  }
}
