.kpi-page {

  .name-column {
    width: 20%;
  }

  .description-column {
    width: 30%;
  }

  .unit-column {
    width: 12%;
  }

  .general-attribute-column {
    width: 12%;
  }

  .tags-column {
    width: 25%;
  }
}
