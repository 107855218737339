.project-group-main-block {

  .MuiFormControl-root:first-child {
    margin-bottom: 24px;
  }

  textarea {
    min-height: 87px;
  }

  .buttons {
    margin-top: 16px;
  }
}
