.chart-base {
  .muted-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #b4b4b4;
  }

  .middle-part {
    margin-bottom: 10px;

    .left-part {
      position: relative;
      padding-bottom: 64px;

      .y-label {
        position: absolute;
        bottom: 64px;
        left: 0;
        transform: translate(0, 25px);

        .rotated-label {
          transform: rotate(-90deg);
          transform-origin: top left;
          white-space: nowrap;
        }
      }

      .y-range {
        margin: 0 20px 0 30px;
        height: 100%;
        display: flex;
        flex-direction: column;
        transform: translateY(10px);

        .y-range-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          &:first-child {
            position: absolute;
            display: block;
            top: -20px;
            right: 0;
          }

          .range-item-value {
            line-height: 20px;
            text-align: right;
          }
        }
      }
    }

    .center-part {
      display: flex;
      flex-direction: column;

      .chart {
        flex: auto;
        position: relative;
        max-height: 470px;
        min-height: 470px;
      }

      canvas {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .x-range-part {
        height: 64px;
        position: relative;

        .navigation-buttons {
          position: absolute;
          left: 0;
          right: 0;
          top: 4px;
        }
      }

      .x-range {
        padding-top: 10px;
        display: flex;
        flex-direction: row;

        &.numeric-range {
          .x-range-item {
            text-align: left;
            overflow: visible;
            min-width: 0;

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              position: absolute;
              right: 0;
              padding-right: 0;

              .range-item-value {
                transform: translateX(50%);
              }
            }

            .range-item-value {
              display: inline-block;
              transform: translateX(-50%);
            }
          }
        }

        .x-range-item {
          text-align: center;
          min-width: 50px;
          line-height: 20px;
          flex: 1;
          word-break: break-word;
          max-height: 40px;
          overflow: hidden;

          &:first-child {
            padding-left: 20px;
          }

          &:last-child {
            padding-right: 20px;
          }
        }
      }

      .x-label {
        padding-top: 10px;
      }
    }

    .right-part {
      padding-left: 20px;
    }
  }
}
