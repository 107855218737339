.project-edit-page {

  .flex-row .content-block:first-child {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  .files-block {
    padding-bottom: 15px;

    .MuiTable-root {
      margin-top: 30px;
    }
  }

  .access-toggle-button {
    margin-top: 6px;

    .MuiButton-label {
      line-height: 21px;
    }

    svg {
      width: 21px;
      height: 21px;
      margin-left: 10px;
    }
  }

}
