.projectDetailsCard {
    height: 600px;
    padding: 0px;
    background-color: white;
    position: relative;

    .projectImage {
        display: block;
        margin: 0 auto;
        height: 200px;
        max-width: 100%;
    }

    .projectImageContainer {
        background-color: #c0c4ca;
    }

    .projectName {
        font-size: 18px;
        font-weight: bold;
    }

    .projectAddressZipCity {
        font-size: 12px;
        margin-top: 5px;
    }

    .projectType {
        font-size: 14px;
        margin-top: 15px;
    }

    .ratingSystem {
        font-size: 14px;
        margin-top: 15px;
        color: gray;
    }

    .ratingSystem {
        margin-top: 5px;
    }
    .ratingSystemSelector {
        border: 0px solid #fff;
        background-color: transparent;
        font-size: 16px;
        margin-top: 5px;
        width: 160px;
    }

    .projectStatus {
        margin-top: 8px;
        font-size: 17px;
    }

    .SCREENING {
        color: var(--ORANGE_COLOR);
    }
    .VERIFICATION {
        color: var(--BLUE_COLOR);
    }
    .REPORTING {
        color: var(--GREEN_COLOR);
    }

    .ratingSystemAward {
        padding: 5px;
    }
    .superScoreChart {
        transform: scale(0.60);
        margin-right: 150px;
    }
    .bottomSection {
        background-color: white;
        padding: 10px;
    }

    .leftColumn {
        float: left;
        white-space: initial;
        width: 160px;
    }
    .rightColumn {
        float: right;
        width: 190px;
    }

    .closeCardButton {
        cursor: pointer;
        position: absolute;
        right: 0;
    }
    .closeCardIcon {
        padding: 1px;
        color: white;
        width: 40px;
        height: 40px;
    }
    .grayBG {
        background-color: #e7ebee;
    }

    .projectsOnSamePositionPagination {
        @extend .grayBG;
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
    }
    .MuiPaper-root {
        @extend .grayBG;
    }

    .ratingSystemAwardImage {
        text-align: center;
    }

    .ratingSystemAwardTitle {
        text-align: center;
    }

    .MuiStepIcon-text {
        visibility: hidden;
    }

    .MuiStep-horizontal {
        padding: 0px 0px;
    }

    .MuiSvgIcon-root {
        font-size: 1rem;
    }

    .MuiStepButton-root,
    .MuiButtonBase-root,
    .MuiStepButton-horizontal {
        padding: 0px 10px;
        transform: scale(0.45);
    }

    .multiprojsSameLatLongPaginationPadding {
        padding: 14px 0px 14px 0px;
    }

    .multiprojsSameLatLongBackButton,
    .multiprojsSameLatLongNextButton {
        @extend .multiprojsSameLatLongPaginationPadding;
        transform: scale(1);
        float: left;
    }

    .multiprojsSameLatLongNextButton {
        float: right;
    }

    .MuiStepper-root {
        @extend .multiprojsSameLatLongPaginationPadding;
    }

    .fullwidth {
        width: 100%;
    }

    .hidden {
        visibility: hidden;
    }
    
    @media (max-width: 1750px) {
        .superScoreChart {
            transform: scale(0.50);
        }
        .rightColumn {
            float: left;
            width: 100px;
        }
    }

    @media (max-width: 1440px) {
        .superScoreChart {
            transform: scale(0.5);
        }
        .rightColumn {
            float: left;
            width: 100px;
            margin-left: 10px;
        }
        .projectsOnSamePositionPagination {
            transform: scale(0.90);
            width: 0;
            height: 44.5px;
        }
    }

    @media (max-width: 1300px) {
        .rightColumn {
            visibility: hidden;
        }
        .multiprojsSameLatLongBackButton,
        .multiprojsSameLatLongNextButton {
            visibility: hidden;
        }
        .multiprojsSameLatLongPaginationPadding {
            padding: 15px 0px 0px 0px;
        }
        .projectsOnSamePositionPagination {
            height: 50px;
        }
    }
}
