.sustainability-system-source {

  .MuiFormHelperText-root.Mui-error {
    transform: translateY(20px);
  }
  .MuiInputBase-input{
    font-size: 20px;
  }
  .MuiInputBase-input.Mui-disabled {
    color: #000000;
  }
}
