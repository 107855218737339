.project-attribute-option {
  border-bottom: 1px solid #c0c0c0;
  margin-top: 10px;

  &:last-child {
    border-bottom: none;
  }

  &:first-child {
    margin-top: 0;
  }

  .flex-row {
    gap:  15px;
  }

  .option-actions {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
  }
}
