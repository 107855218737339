.permissions-dialog {
  .dialog-body {
    margin-top: 26px;

    .modal-table {
      table-layout: fixed;

      .MuiAutocomplete-inputRoot {
        height: 32px;
      }

      .MuiFormHelperText-root.Mui-error {
        margin-top: 0;
        transform: translateY(-8px);
      }

      .user-cell {
        width: 200px;
        vertical-align: top;
      }

      .permission-cell {
        vertical-align: top;
      }

      .actions-cell {
        text-align: right;
        width: 30px;
      }
    }

  }
}
