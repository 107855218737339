.grouping-element-chart {
  position: relative;
  width: 300px;
  height: 300px;

  .middle-content {
    pointer-events: none;
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #1C304D;

    .header-text {
      white-space: normal;
      word-break: break-word;
      max-height: 90px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      overflow: hidden;
    }

    .value-text, .potential-text, .potential-text-low {
      font-size: 20px;
      line-height: 26px;
      font-weight: 400;
    }

    .value-text {
      color: var(--PRIMARY_BACKGROUND_COLOR);
    }

    .potential-text {
      color: var(--SECONDARY_BACKGROUND_COLOR);
    }

    .potential-text-low {
      color: var(--RED_COLOR);
    }

    &.text-gray {
      .header-text, .value-text, .potential-text {
        color: var(--NEUTRAL_TEXT_COLOR);
      }
    }
  }
}
