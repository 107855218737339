.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--PRIMARY_BACKGROUND_COLOR);
  text-align: center;
  margin-left: 25px;
  margin-right: 20px;
  display: inline-block;

  span {
    font-weight: 400;
    font-size: 20px;
    line-height: 50px;
    align-items: center;
    color: #EFEFEF;
  }
}
