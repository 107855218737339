.project-attributes-page {
  .project-types-column {
    margin-right: 50px;
  }

  .project-headers {
    gap: 0 15px;
  }

  .fields-container {
    display: flex;
    height: calc(100vh - 190px);
    gap: 1rem;

    .fields-left, .fields-right {
      flex: 1;
      overflow: hidden auto;
    }

    .field-placeholder {
      min-height: 53px;
      margin-top: 16px;
    }
  }
}
