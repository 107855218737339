.project-filter {
  justify-content: end;

  .filter-popover {
    padding-left: 30px;
  }

  .loading-overlay {
    padding-left: 10px;
    margin-left: -10px;
    margin-top: -10px;
    padding-top: 10px;

    .check-tree {
      margin-left: 0;
    }
  }
}
