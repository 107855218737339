.tenants-dialog {
  .MuiDialogTitle-root {
    margin-bottom: 24px;
    position: relative;

    button.MuiButtonBase-root {
      top: 0;
      right: 0;
    }
  }
  .MuiPaper-root {
    box-sizing: border-box;
    width: 438px;
    padding: 0;

    .loading-overlay {
      box-sizing: border-box;
      padding: 32px;
      width: 100%;
      min-height: auto;
    }

    .MuiDialogContent-root {
      padding: 0;
    }
    .MuiFormControl-root.MuiFormControl-fullWidth {
      margin-bottom: 16px;
    }
    .MuiFormControlLabel-root {
      width: max-content;
      display: flex;
      align-items: center;
      margin-left: 0;

      .MuiButtonBase-root {
        order: 1;
        margin-left: 28px;
      }
    }

    .flex-row > * {
      width: calc(50% - 16px);
    }
  }
}
