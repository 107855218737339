.filter-blocks {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 30px;

  .reset-button {
    line-height: 73px;
    margin-left: 40px;
  }

  &.disabled {
    .filter-block {
      cursor: default;
      opacity: 0.7;
    }
  }

  .filter-block {
    width: auto;
    height: 73px;
    background: var(--PRIMARY_TEXT_COLOR);
    margin-left: 50px;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    box-shadow: 0 2px 2px #00000029;

    &:first-child {
      margin-left: 0;
    }

    .block-content {
      display: flex;
      flex-direction: row;
      justify-content: left;
    }

    h3 {
      font-size: 14px;
      line-height: 19px;
      font-weight: normal;
      color: #000000;
      padding: 0;
      margin: auto 0 12px 10px;
    }

    .amount {
      font-size: 40px;
      line-height: 53px;
      font-weight: bold;
      color: #000000;
      padding: 0;
    }

    .line {
      border-radius: 1.5px;
      border: 1.5px solid lightgray;
      background: lightgray;
    }

    &.active {
      background: lightgray;
      cursor: default;

      &.deselectable {
        cursor: pointer;
      }

      h3, .amount {
        color: var(--PRIMARY_TEXT_COLOR);
      }

      .line {
        border-color: var(--PRIMARY_TEXT_COLOR) !important;
        background: var(--PRIMARY_TEXT_COLOR) !important;
      }
    }
  }
}
