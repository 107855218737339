.rating-system-grouping {
  padding: 0 2px 0 0;
  height: 100%;
  overflow: hidden;

  .filters {
    margin: -10px 0 16px 0;
  }

  .ignored-value {
    float: right;
    color: rgba(0, 0, 0, 0.3);
    margin-left: 8px;
  }

  .MuiTableRow-root {
    border-bottom: 2px solid #EFEFEF;

    &.excluded, &.calculation-excluded {
      .cell-inner {
        color: transparent;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      }
    }

    &.excluded .collapse-trigger {
      display: none;
    }
  }

  .footer-buttons {
    display: none;
  }

  .MuiTableCell-root {
    &:nth-child(3) {
      box-shadow: inset -2px 0 #efefef;

      .column-header {
        margin-right: 2px;
      }
    }
  }

  .sustainability-row-CATEGORY, .sustainability-row-CATEGORY .MuiTableCell-root {
    background-color: #D5DBE8;
  }

  .sustainability-row-CRITERION, .sustainability-row-CRITERION .MuiTableCell-root {
    background-color: #EBEFFB;
  }

  .sustainability-row-INDICATOR, .sustainability-row-INDICATOR .MuiTableCell-root {
    background-color: #F1F3FF;
  }

  .MuiTableContainer-root.advanced-table .MuiTableHead-root .MuiTableCell-head.group {

    .column-group-current {
      background-color: var(--PRIMARY_BACKGROUND_COLOR);
      color: var(--PRIMARY_TEXT_COLOR);
    }

    .column-group-potential {
      background-color: var(--SECONDARY_BACKGROUND_COLOR);
      color: var(--SECONDARY_TEXT_COLOR);
    }

    .column-group-metrics {
      background-color: var(--PRIMARY_TEXT_COLOR);
      color: var(--NEUTRAL_TEXT_COLOR);
      border-top: 1px solid rgba(41, 65, 139, 0.2);

      &.first-in-group {
        border-left: 1px solid rgba(41, 65, 139, 0.2);
      }

      &.last-in-group {
        border-right: 1px solid rgba(41, 65, 139, 0.2);
      }
    }

    .column-group-current-fulfillment,
    .column-group-potential-fulfillment,
    .column-group-difference-score {
      background-color: var(--PRIMARY_TEXT_COLOR);
      color: var(--NEUTRAL_TEXT_COLOR);
      border-top: 1px solid rgba(41, 65, 139, 0.2);

      &.first-in-group {
        border-left: 1px solid rgba(41, 65, 139, 0.2);
      }

      &.last-in-group {
        border-right: 1px solid rgba(41, 65, 139, 0.2);
      }
    }
  }

  .MuiTableContainer-root.advanced-table .MuiTableBody-root .MuiTableCell-root {
    &.column-group-current {
      &.first-in-group:before, &.last-in-group:after {
        border-color: #B5B5B5;
      }
    }

    &.column-group-potential {
      &.first-in-group:before, &.last-in-group:after {
        border-color: #B2b2b2a1;
      }
    }

    &.column-group-metrics {
      &.first-in-group:before, &.last-in-group:after {
        border-color: #C5C5C5;
      }
    }

    &.column-group-current-fulfillment,
    &.column-group-potential-fulfillment,
    &.column-group-difference-score {
      &.first-in-group:before, &.last-in-group:after {
        border-color: #C5C5C5;
      }
    }
  }

  .MuiFormControl-root.MuiTextField-root.marked {
    background-color: rgba(#E8DF00, 0.3);
  }

  .indicator-status-select .MuiSelect-select {
    padding: 5px;
  }

  .footer-total {
    white-space: nowrap;
    direction: rtl;
  }

  .validation-failed {
    color: var(--RED_COLOR);
  }

  .reason-badge {
    display: inline-block;
    background-color: #505050CC;
    color: var(--PRIMARY_TEXT_COLOR);
    box-shadow: 0 1px 1px #00000029;
    border-radius: 20px;
    padding: 5px 10px 5px 20px;
    font-size: 16px;
    line-height: 21px;
    white-space: nowrap;
    cursor: pointer;

    svg {
      height: 16px;
      transform: translateY(2px);
    }

    &.active {
      background-color: #019FE3CC;
    }
  }

  .reason-clear {
    padding: 3px;
  }

  .status-badge {
    line-height: 34px;
  }

  .MuiSelect-select .status-badge {
    line-height: 19px;
  }

  .status-indicator {
    transform: translateX(-12px);
  }

  .indicatorPotentialHelpIcon {
    margin-left: 2px;
    position: absolute;
  }

}

.rating-system-status-indicator-popover {
  .MuiPaper-root {
    width: 232px;
    padding: 8px 0;
  }

  .flex-row {
    padding: 8px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    svg {
      color: #007C05;
    }
  }

  .separator {
    border-bottom: 2px solid #D0D0D0;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.custom-name-tooltip { 
  margin-left: 5px;
  vertical-align: middle;
}