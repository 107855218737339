.grouping-element-before-bar-chart {
  width: 42px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transform: translateX(-100%);

  .vertical-block {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    width: 100%;
    height: 399px;

    .title {
      border-left: 2px solid rgba(28, 48, 77, 0.1);
      width: 100%;
      height: 370px;
      font-size: 18px;
      line-height: 23px;
      color: rgba(28, 48, 77, 0.5);
      cursor: default;

      .hit-box {
        position: absolute;
        width: 100%;
        height: 370px;
        transform: translateX(-50%);
      }
    }
  }
}
