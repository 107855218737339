.project-group-analytics-chart {
  margin-left: 20px;

  .projects-vs-kpi-chart {
    margin-top: 10px;

    .middle-part {
      margin-bottom: 20px;
    }
  }

  .kpi-vs-kpi-chart {
    margin-top: 42px;

    .middle-part {
      margin-bottom: 20px;
    }

    .projects {
      max-width: 200px;

      .project-item {
        line-height: 24px;
        margin-bottom: 16px;
      }
    }

    .value-dot-wrapper {
      position: absolute;
      padding: 5px;
      transform: translate(calc( -100% - 10px ), calc( -50% - 1px ));
      z-index: 1;

      .value-dot {
        margin: 0;
        pointer-events: none;
      }
    }

    .value-dot {
      min-width: 10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #c0c0c0;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.32);
      margin-right: 8px;
      margin-top: 6px;
    }

    .guide-lines {
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0;
      transition: opacity 0.2s linear;

      background: rgba(#002965, 0.05);
      border: 1px dashed #005EBC;
      border-left: none;
      border-bottom: none;
    }
  }

  .header {
    line-height: 32px;
    transform: translateY(-42px);
    pointer-events: none;

    .legend {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .legend-item {
        margin-left: 24px;
        font-weight: 400;
        font-size: 14px;
        color: #B4B4B4;
        pointer-events: all;
        line-height: 25px;

        &:first-child {
          margin-left: 0;
        }

        .sample-line {
          width: 20px;
          height: 0;
          border-bottom: 2px solid #002965;
          margin-right: 8px;
          display: inline-block;
          transform: translateY(-3px);

          &.average {
            border-color: #62B0FF;
          }
        }
      }
    }
  }

  .versus {
    padding: 0 10px;
    line-height: 25px;
    color: #707070;
  }

  .chart-inner {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    left: 20px;

    .chart-bar-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .chart-bar {
        width: 38px;
        margin: 0 auto;
        padding-top: 4px;
      }

    }
  }
}
