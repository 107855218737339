.check-tree {
  padding: 0;
  list-style: none;

  .MuiCheckbox-root {
    padding: 4px;
  }

  .check-tree-node {
    padding-bottom: 4px;

    .check-tree-node-title {
      font-size: 16px;
      letter-spacing: 0.36px;
      color: #000000DE;
      display: flex;
      flex-direction: row;

      .node-name:first-child {
        cursor: default;
        margin-left: 10px;
        font-weight: 700;
      }
    }

    .node-name {
      line-height: 32px;
      cursor: pointer;
    }

    &.disabled .node-name {
      cursor: default;
    }

    .MuiIconButton-root.collapse-trigger {
      padding: 0;
      width: 32px;
      height: 32px;
      margin-left: 6px;
      margin-top: 0;
    }
  }

  ul {
    list-style: none;
    padding: 0 0 0 37px;
    margin-bottom: 4px;

    .check-tree-node {
      padding-bottom: 0;

      .node-name {
        line-height: 34px;
      }

      .MuiIconButton-root.collapse-trigger {
        margin-top: 0;
      }
    }

    .MuiCheckbox-root {
      padding: 5px;
    }
  }


  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #019FE3;

    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
    }
  }
}
