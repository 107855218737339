.kpi-rating-system-grouping {

  .sustainability-row-KPI, .sustainability-row-KPI .MuiTableCell-root {
    background-color: #F1F3FF;
  }

  .MuiTableCell-root {
    &:nth-child(2) {
      box-shadow: inset -2px 0 #efefef;

      .column-header {
        margin-right: 2px;
      }
    }

    &:nth-child(3) {
      box-shadow: none;

      .column-header {
        margin-right: 0;
      }
    }
  }

}
