.sun-burst-chart {
  min-width: 535px;
  height: 535px;
  margin-bottom: 30px;
  position: relative;

  svg {
    max-width: 535px;

    path {
      cursor: pointer;
    }
  }

  .middle-content {
    pointer-events: none;
    position: absolute;
    top: 135px;
    left: 135px;
    right: 135px;
    bottom: 135px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #1C304D;

    .header-text {
      white-space: normal;
      word-break: break-word;
      max-height: 90px;
      font-size: 22px;
      line-height: 30px;
      font-weight: 400;
      overflow: hidden;
    }

    .value-text, .potential-text {
      font-size: 20px;
      line-height: 26px;
      font-weight: 400;
    }

    .value-text {
      color: var(--PRIMARY_BACKGROUND_COLOR);
    }

    .potential-text {
      color: var(--SECONDARY_BACKGROUND_COLOR);
    }
  }
}
