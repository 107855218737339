.tenant-select {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-items: center;
  background-color: #F2F4F7;

  .middle-area {
    width: 470px;
    margin: auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    border: 1px solid #DDDDDD;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;

    h1 {
      color: #333;
      margin: 0 0 32px 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
    }

    p {
      margin: 0 0 16px 0;
      font-size: 16px;
      line-height: 24px;
    }

    .tenant {
      margin: 0 0 8px 0;
      display: flex;
      cursor: pointer;
      background: #F2F4F7;
      border-radius: 10px;
      padding: 8px;
      border: 1px solid white;
      box-sizing: border-box;

      &:hover {
        background-color: #F7F8FC;
      }

      .logo {
        width: 90px;
        height: 60px;
        padding-right: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
          max-width: 90px;
          max-height: 60px;
          margin: auto;
        }
      }

      h2 {
        margin: 0 0 2px 0;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
      }

      p {
        margin: 0 0 4px 0;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #707070;
      }
    }

    .MuiButton-root {
      margin-top: 10px;
      margin-bottom: -10px;
      float: right;
    }
  }
}
