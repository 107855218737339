.widget-wrapper {
  box-sizing: border-box;
  width: 100% ;
  min-width: 520px;
  height: 160px;
  min-height: auto;
  padding: 20px 15px;
  background: var(--PRIMARY_TEXT_COLOR);
  box-shadow: 0px 2px 2px #00000029;

  h1,h2, p {
    margin: 0;
    color: #1C304D;
  }

  h1 {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  h2 {
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
    line-height: 16px;
    white-space: pre-line;
  }

  & > .flex-row {
    & > *:not(:last-child) {
      margin-right: 10%;
    }
  }

  .loading-overlay-spinner {
    margin-bottom: 0 !important;
  }

  .bottom-line {
    margin-top: 12px;
    width: 80px;
    height: 3px;
    background: transparent;
  }
}