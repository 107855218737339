.award-column {
  display: flex;
  margin-top: -2px;
  margin-bottom: -2px;

  .award-image {
    max-height: 50px;
    max-width: 50px;
    margin-right: 8px;
  }

  .award-title {
    line-height: 50px;
  }
}

.name-column-template {
  svg {
    width: 21px;
    height: 21px;
    margin-right: 5px;
  }
}

.name-column-template-collapse {
  margin-top: 5px;
}

.simple-table-sort .MuiTableContainer-root .MuiTableCell-root.MuiTableCell-body.system-score-column {
  padding-left: 30px;
  padding-right: 30px;
  text-align: right;

  .flex-left {
    text-align: right;
    padding-right: 8px;
    flex: auto;
  }

  .flex-right {
    text-align: right;
    min-width: 64px;
  }
}
