.breadcrumb {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  .crumb {
    height: 24px;
    line-height: 24px;

    .MuiLink-root {
      cursor: pointer;
    }

    span {
      display: inline-block;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
      color: #1C304D80;

      &::before {
        content: '';
        border-left: 2px solid #1C304D80;
        padding-right: 15px;
        margin-left: 15px;
      }
    }

    &:first-child {
      a {
        padding-left: 30px;
        position: relative;

        svg {
          left: 0;
          top: 0;
          position: absolute;
          height: 19px;
          width: 19px;
        }
      }

      span {
        &::before {
          content: none;
        }
      }

    }

    &:last-child span {
      color: #1C304D;

      &::before {
        border-color: #1C304D;
      }
    }
  }
}
