.custom-dialogs .custom-dialog .add-project-group-project-dialog {

  h2 {
    margin-bottom: 20px;
  }

  .search-filter-box {
    height: 70px;
    box-sizing: border-box;
    border-top: 1px solid #D0D0D0;
    margin: 0 -30px 0 -30px;
    padding: 23px 30px;
    position: relative;

    .project-filter {
      padding-left: 30px;
    }

    .search-box {
      cursor: text;
      margin: -23px -30px;
      padding: 23px 30px 20px 30px;
    }
  }

  .loading-overlay {
    margin-bottom: -28px;

    .simple-table-sort {
      .MuiTableContainer-root {
        margin: 0;

        .MuiTableRow-head {
          .MuiTableCell-head {
            position: sticky !important;
            z-index: 1;
            top: 0;
            background: #ECECEC;
          }
        }
      }
    }
  }

  .action-header-cell {
    width: 177px;
    text-align: right;
  }

}
