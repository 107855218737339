.image-viewer {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  z-index: 1000;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.3s ease-in-out;

  &.open {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }

  .modal-content {
    margin: auto;
    display: block;
    max-width: 80%;
    max-height: 80%;
  }

  @media only screen and (max-width: 700px) {
    .modal-content {
      width: 100%;
    }
  }
}
