.grouping-elements-tab {
  .breadcrumb {
    margin-top: 15px;
    margin-bottom: 24px;
  }

  .grouping-charts {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(auto-fill, minmax(300px, auto));
    margin-bottom: 50px;
  }

  .grouping-bar-charts {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 50px;

    .row-break {
      width: 100%;
      border-top: 2px solid rgba(28, 48, 77, 0.1);
      transform: translateY(-71px);
    }
  }
}
