.two-options-toggle {
  white-space: nowrap;

  &.disabled {
    opacity: 0.5;
  }

  .MuiSwitch-colorPrimary, .MuiSwitch-colorPrimary.Mui-checked {
    color: var(--PRIMARY_BACKGROUND_COLOR) !important;
  }

  .MuiSwitch-track {
    background-color: var(--PRIMARY_BACKGROUND_COLOR) !important;
    opacity: 0.3 !important;
  }

  label {
    cursor: pointer;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0;
    color: var(--PRIMARY_BACKGROUND_COLOR);
    text-transform: uppercase;
    opacity: 0.5;
    user-select: none;

    &.active {
      color: var(--PRIMARY_BACKGROUND_COLOR);
      opacity: 1;
    }
  }

  &.disabled label {
    cursor: default;
  }

}
