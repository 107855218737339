.roles-table {
  .active span {
    text-transform: uppercase;
    color: var(--GREEN_COLOR);
    font-weight: bold;
  }

  .inactive span {
    text-transform: uppercase;
    color: var(--NEUTRAL_TEXT_COLOR);
    font-weight: bold;
  }

  .default-column span {
    text-transform: uppercase;
    font-weight: bold;
    color: var(--NEUTRAL_TEXT_COLOR);
  }
}
