
.k-floating-label-container {
  bottom: 8px;

  .datepickerController {
      top: 5.5px;
      
      &.k-datepicker {
        margin-bottom: 7px;
        border-bottom-width: 2px;
        border-bottom-color: #707070;
      }

      &.k-datepicker:hover {
        border-color: #707070;
      }

      &.k-datepicker::after {
        transform: matrix(0, 0, 0, 0, 0, 0) !important;
      }

      .k-input {
        input {
          padding: 1px !important;
        }
      }

      .k-input-flat:focus-within {
        border-bottom-color: #008dc9 !important ;
      }
  }

  .k-label {
    left: 0px !important;
  }

  .k-text-error {
    color: #d32f2f !important;
  }

  .k-invalid {
    border-bottom-color: #d32f2f !important;
  }
}

.k-focus .k-label {
  color: #008dc9 !important;
}