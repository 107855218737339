.sustainability-system-grouping {
  padding: 0 50px 0 50px;
  height: 100%;
  overflow: hidden;

  .filters {
    margin: -10px 0 16px 0;
  }

  .ignored-value {
    float: right;
    color: rgba(0, 0, 0, 0.3);
    margin-left: 8px;
  }

  .MuiTableRow-root {
    border-bottom: 1px solid white;
  }

  .MuiTableCell-root {
    &:nth-child(3) {
      box-shadow: inset -2px 0 #efefef;

      .column-header {
        margin-right: 2px;
      }
    }
  }

  .sustainability-row-CATEGORY, .sustainability-row-CATEGORY .MuiTableCell-root {
    background-color: #D5DBE8;
  }

  .sustainability-row-CRITERION, .sustainability-row-CRITERION .MuiTableCell-root {
    background-color: #EBEFFB;
  }

  .sustainability-row-INDICATOR, .sustainability-row-INDICATOR .MuiTableCell-root {
    background-color: #F1F3FF;
  }

  .MuiFormControl-root.MuiTextField-root.marked {
    background-color: rgba(#E8DF00, 0.3);
  }
}
