.custom-dialogs .custom-dialog .date-picker-dialog {
  h2 {
    margin-bottom: 16px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .flex-row {
    margin-bottom: 20px;

    .MuiButton-text {
      margin-top: 17px;
    }
  }

  .date-picker{
    top: 5px;
    &.k-datepicker {
      margin-bottom: 7px;
      border-bottom-width: 2px;
      border-bottom-color: #707070;
    }

    &.k-datepicker:hover {
      border-color: #707070;
    }

    &.k-datepicker::after {
      transform: matrix(0, 0, 0, 0, 0, 0) !important;
    }

    .k-input {
      input {
        padding: 1px !important;
      }
    }

    .k-input-flat:focus-within {
      border-bottom-color: #008dc9 !important ;
    }
  }
  
}
