.goals-tab {
  .breadcrumb {
    margin-top: 15px;
    margin-bottom: 24px;
  }

  .grid-blocks {
    display: grid;
    grid-template-columns: repeat(auto-fill, 440px);
    grid-gap: 30px;
    margin-bottom: 50px
  }
}
