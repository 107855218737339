
.chips {
  margin-top: -5px;
  margin-right: -5px;

  .MuiChip-root {
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 5px;
    background: #EFEFEF;
    height: 26px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 16px;

    .MuiChip-icon {
      color: #505050CC;
      width: 16px;
      height: 16px;
    }

    .MuiChip-deleteIcon {
      width: 16px;
      height: 16px;
    }
  }

  .add-chip {
    background: transparent;

    input {
      border: none;
      background: transparent;
      height: 100%;
      width: 100px;

      &:focus {
        outline: none;
      }
    }

    svg {
      margin-right: 4px;
    }
  }
}
