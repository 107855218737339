

.grouping-element-bar-chart {
  position: relative;
  width: 142px;
  min-height: 470px;
  margin-right: 78px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.last-in-row {
    margin-right: 0;
  }

  .chart-bar {
    width: 60px;
    height: 370px;
    background: var(--NEUTRAL_BACKGROUND_COLOR);
    border-radius: 10px 10px 0 0;
    position: relative;
    margin: 0 41px;
    cursor: pointer;

    .maximum-text {
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: rgba(28, 48, 77, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transform: translateY(-100%);
      padding-bottom: 8px;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      cursor: default;
    }

    .bar-inner {
      border-radius: 10px 10px 0 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .potential-bar {
      background: var(--SECONDARY_BACKGROUND_COLOR);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 75%;

      .potential-text {
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        text-transform: uppercase;
        color: var(--SECONDARY_TEXT_COLOR);
        padding-top: 8px;
      }
    }

    .potential-bar-low {
      background: var(--RED_COLOR);

      .potential-text {
        color: var(--PRIMARY_TEXT_COLOR);
      }
    }

    .current-bar {
      background: var(--PRIMARY_BACKGROUND_COLOR);
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;

      .current-text {
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        text-transform: uppercase;
        color: #EFEFEF;
        padding-top: 8px;
      }
    }
  }

  .chart-footer {

    .title-text {
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      color: #1C304D;
      height: 46px;
      margin-top: 24px;
    }
  }
}
