.drawer {
  height: 100vh;

  // deactivate Kendo hover
  .k-drawer-item:hover {
    background-color: transparent !important;
  }

  .k-drawer-item:focus {
    background-color: transparent !important;
  }

  .k-drawer-item {
    padding-block: 0;
    line-height: 1;
    padding-inline: 14px;
  }

  .k-drawer-wrapper {
    height: calc(100vh - 60px);
  }

  .selectedExpanded {
    background-color: #dfe2e8 !important;
    .pointIcon svg {
      fill: #002965 !important;
    }
  }

  .selectedCollapsed {
    .iconCollapsed {
      background-color: #dfe2e8 !important;
    }
  }

  .header {
    width: 100%;

    display: flex;
    justify-content: space-between;
    // margin: 10px 0 10px 0;
    min-height: 80px;
    max-height: 80px;

    .content {
      display: flex;
    }

    .logo-container {
      padding: 0;
      width: 80px;
      height: 60px;
      overflow: hidden;
      margin: 10px;

      .logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: left;
      }
    }

    .tenant {
      font-size: 16px;
      font-weight: 600;
      margin: auto 0 auto 0;
      cursor: default;
    }

    .icon {
      margin: auto 0 auto 15px !important;
      stroke: #00000029;
      transition: stroke 0.3s ease;
      display: flex;
      justify-content: space-around;
      padding: 10px !important;

      &:hover {
        stroke: #002965;
        border-radius: 5px;
        background-color: #dfe2e8;
      }
    }
  }

  .itemContainer {
    display: flex;
    gap: 15px;
    margin-top: 10px;

    .iconCollapsed {
      &:hover {
        background-color: #dfe2e8 !important;
      }

      padding: 20px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
    }

    .pointIcon svg {
      fill: #dfe2e8;
      transition: fill 0.3 ease;
    }

    .item {
      margin: auto 0 auto 0;
      .text {
        font-size: 20px;
        font-weight: 500;
        color: #002965;
      }
    }

    .chevron {
      margin: auto 0 auto auto;

      fill: #dfe2e8;
      transition: fill 0.3 ease;
    }
  }

  .itemContainerOpen {
    width: 100%;
    padding: 18px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    border-radius: 10px;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #dfe2e8 !important;

      .pointIcon svg {
        fill: #002965;
      }

      .chevron svg {
        fill: #002965;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    background-color: rgb(239, 246, 255);
    justify-content: space-between;
    padding-inline: 30px !important;
    padding-block: 10px;

    &:hover {
      background-color: rgb(239, 246, 255) !important;
    }

    .content {
      display: flex;

      .avatar {
        display: flex;
        align-items: center;
        margin-right: 30px;
      }

      .info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 200px;
        margin-top: 15px;

        .name {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .icon {
      margin: auto 0 auto 0;
      fill: #00000029;
      transition: fill 0.3s ease;
      display: flex;
      justify-content: space-around;
      padding: 10px;
      &:hover {
        fill: #002965;
        border-radius: 5px;
        background-color: #dfe2e8;
      }
    }
  }

  // for the content next to the sidebar - 100% hight -> ensure ability to scroll
  .content {
    height: 100%;
  }
}

.separator-collapsed {
  .k-drawer-separator {
    width: 60px;
    margin: 0 20px 0 20px;
  }
}

.separator-expanded {
  .k-drawer-separator {
    margin: 0 20px 0 20px;
  }
}

.k-popup {
  box-shadow: none !important;
}

.popup-content {
  position: relative;
  top: calc(100% - 180px) !important;
  left: 390px !important;
  background-color: white !important;
  border-radius: 10px !important;
  padding: 10px;
  border: 1px solid #f2f4f7;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 2px 3px 5px #00000029;

  .popup-link {
    width: 180px;
    padding: 10px;
    border-radius: 10px;
    color: #1c304d;
    transition: background-color 0.3 ease;

    &:hover {
      background-color: #dfe2e8 !important;
    }

    &:focus {
      background-color: #dfe2e8 !important;
    }
  }
}
