.traffic-light {
  display: flex;

  .circle {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
    opacity: 0.3;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    &.red {
      background: transparent linear-gradient(180deg, var(--RED_COLOR) 0%, #D11A00 100%) 0 0 no-repeat padding-box;
    }

    &.yellow {
      background: transparent linear-gradient(180deg, #FFE200 0%, #D9C000 100%) 0 0 no-repeat padding-box;
    }

    &.green {
      background: transparent linear-gradient(180deg, var(--GREEN_COLOR) 0%, #008928 100%) 0 0 no-repeat padding-box;
    }

    &.selected {
      opacity: 1;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      opacity: 0.75;
    }
  }

  &.read-only .circle {
    cursor: default;

    &:hover {
      opacity: 0.3;
    }
  }
}
