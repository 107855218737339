@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinnerDash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.loading-overlay {
  position: relative;
  overflow: hidden;

  .loading-overlay-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    text-align: center;
    font-size: 1.2em;
    color: #FFF;
    background: rgba(0, 0, 0, 0.7);
    z-index: 800;
    transition: opacity 500ms ease-in;
    opacity: 0;
    pointer-events: none;

    .loading-overlay-content {
      margin: auto;

      .loading-overlay-spinner {
        position: relative;
        margin: 0 auto 10px auto;
        width: 50px;
        max-height: 100%;

        &:before {
          content: "";
          display: block;
          padding-top: 100%;
        }

        svg {
          animation: rotate360 2s linear infinite;
          height: 100%;
          transform-origin: center center;
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;

          circle {
            animation: spinnerDash 1.5s ease-in-out infinite;
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
            stroke-linecap: round;
            stroke: #FFF;
          }
        }
      }
    }
  }

  &.active > .loading-overlay-overlay {
    opacity: 1;
    pointer-events: all;
  }
}


