.kpi-system-page {
  height: 100vh;

  .page-header {

    .page-title {
      font-weight: bold;
      font-size: 28px;
      line-height: 37px;
      letter-spacing: 0;
      color: #000000;
      padding: 0;
      margin: 0 10px 0 0;
    }
  }

  .system-form {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .table-wrapper {
      overflow: hidden;
      flex: auto;

      .MuiTableContainer-root.advanced-table {
        overflow: auto;
      }
    }
  }

  .fabs {
    svg {
      color: var(--PRIMARY_TEXT_COLOR);
    }
  }
}
