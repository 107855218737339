.search-badge {
  display: inline-block;
  white-space: nowrap;
  background-color: #D7EAF6;
  border-radius: 10px;
  padding: 5px 0 5px 10px;
  line-height: 19px;
  font-size: 14px;
  letter-spacing: 0.7px;
  margin: 0 10px 0 0;

  button {
    margin: 0 2px;
  }

  .search-icon.MuiSvgIcon-root {
    font-size: 20px;
    color: #000000DE;
    transform: translateY(5px);
    margin-right: 5px;
  }
}
