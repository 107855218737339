.project-group-analytics-kpi {
  width: 50%;
  margin-right: 10px;

  .loading-overlay {
    margin: -20px -30px;
  }

  .simple-table-sort {
    margin-bottom: 0;

    .name-column {
      width: 200px;
      min-width: 200px;
      line-height: 30px;
    }

    .number-column {
      text-align: right;
      width: 1%;
      line-height: 30px;
      white-space: nowrap;

      .flex-auto {
        line-height: 30px;
      }
    }

    .icon-button-wrapper {
      width: 30px;
    }

    .MuiIconButton-root {
      margin-left: 5px;
      margin-right: -5px;

      svg {
        color: #002965;
      }
    }

    .MuiTableRow-root.selected-row {
      background-color: #99B3FF;
    }

    .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root {
      border-bottom: none;
    }

    .MuiTableContainer-root .MuiTableRow-head .MuiTableCell-head {
      background: #ECECEC;
      padding-right: 0;

      &:last-child {
        padding-right: 24px;
      }
    }

    .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:last-child {
      padding-right: 24px;
    }
  }

  .search-box {
    margin: 0;
    padding: 20px 30px;
  }
}
