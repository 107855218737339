.project-group-page {
  .top-block {
    width: 100%;
    display: flex;
  }

  .page-header {
    margin-bottom: 0;
  }

  .main-block {
    min-width: 520px;
    width: 520px;
    margin-right: 20px;
  }

  .right-block {
    .content-block-inner {
      box-sizing: content-box;
    }
  }

  .content-block-component {
    margin-bottom: 20px;
    overflow: hidden;

    .content-block-inner {
      height: 100%;
    }
  }

}
