.project-page {

  .left-block {
    margin-right: 50px;
  }

  .files-block {
    padding-bottom: 15px;
    width: 720px;
    box-sizing: border-box;
    background-image: url('../../svg/document.svg');
    background-repeat: no-repeat;
    background-position: 265px 15px;

    h2 {
      position: absolute;
    }

    .MuiTable-root {
      margin-top: 35px;
    }

    .amount {
      transform: translateY(49px);
    }

    .loading-overlay {
      overflow: visible;
    }
  }

}
