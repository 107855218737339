

.versions-table {
  border: none !important;
  max-height: calc(43px * 6);

  .MuiTableHead-root {
    .MuiTableCell-sizeSmall {
      color: var(--NEUTRAL_TEXT_COLOR);
      font-size: 14px;
      letter-spacing: .7px;
    }
  }

  .MuiTableBody-root {
    .MuiTableCell-sizeSmall {
      &:first-child {
        color: var(--PRIMARY_BACKGROUND_COLOR);
      }
      &.selected {
        font-weight: bold;
      }
    }
  }

  .MuiTableCell-sizeSmall {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    padding: 10px 0;
    width: 35%;
    border-bottom: 2px solid #D0D0D0;
    background: var(--PRIMARY_TEXT_COLOR);

    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-left: 20px;
    }
    .version {
      cursor: pointer;
    }
    .userName {
      white-space: nowrap;
    }
  }

}