.files-table {
  margin: 0 -15px;

  .files-search {
    text-align: right;

    .MuiTextField-root {
      width: 200px;
      box-sizing: border-box;
    }

    svg {
      color: #505050CC;
      width: 16px;
      height: 16px;
    }

    .MuiOutlinedInput-adornedStart {
      padding-left: 12px;
    }

    .MuiOutlinedInput-input {
      padding: 0 12px 0 0;
      height: 28px;

      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;

      &::placeholder {
        color: #505050CC;
        opacity: 1;
      }
    }
  }

  .MuiTable-root {
    table-layout: fixed;

    .MuiTableCell-head {
      text-transform: uppercase;
      border: 0;
      padding: 5px 15px;
      font-size: 20px;
      line-height: 26px;
      color: var(--NEUTRAL_TEXT_COLOR);
      font-weight: normal;
      letter-spacing: 1px;

      &:first-child {
        width: 30%;
      }
    }

    .actions-cell {
      width: 30px;
      text-align: right;
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        background-color: #FAFAFB33;

        &.even {
          background-color: transparent;
        }

        .MuiTableCell-body {
          padding: 5px 15px;
          border: 0;
          line-height: 32px;
          vertical-align: top;

          .chips {
            overflow: hidden;
          }

          a {
            word-break: break-word;
          }
        }
      }
    }
  }
}
