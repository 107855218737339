.project-group-dialog {
  .dialog-body {
    margin-bottom: 30px;

    textarea {
      min-height: 85px;
    }

    .chars-left {
      position: absolute;
      right: 0;
      margin: 0;
    }

    p.Mui-error {
      margin: 0;
    }
  }
}
