.files-list {
  h2.files-header {
    .MuiIconButton-sizeSmall {
      padding: 0;
    }

    margin-bottom: 8px;
  }

  .files {
    margin-bottom: 8px;
  }

  .empty {
    margin-bottom: 18px;
    color: #7F7F7F;
  }

  .MuiChip-root, a {
    margin-right: 8px;
    margin-bottom: 5px;
    max-width: 100%;
  }

  a {
    display: inline-block;
  }
}
