.textblock-page.page-with-table {

  .page-header > .flex-col:first-child, .page-layout > .flex-col:first-child {
    width: 305px;
    min-width: 305px;
  }

  .actions-table {

    .type-column {
      min-width: 210px;
      max-width: 210px;
    }

    .name-column {
      width: 25%;
    }

    .description-column {
      width: 25%;
    }

    .tags-column {
      width: auto;
    }

    .actions-column {
      text-align: right;
      min-width: 130px;
      max-width: 130px;
    }

    .MuiFormGroup-root {

      .MuiTypography-root {
        white-space: nowrap;
        line-height: 32px;
      }

      .MuiIconButton-root {
        padding: 0;
        margin-right: 5px;
      }
    }
  }
}
