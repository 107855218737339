.Toastify__toast-container {
  width: 33vw;
  min-width: 33vw;
  max-width: 500px;

  .Toastify__toast {
    border-radius: 10px;
    box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.3);
    border: 1px solid #EAEAEA;

    .Toastify__toast-body {
      font-size: 16px;
      line-height: 21px;
      color: #333333;
    }
  }
}
