.MuiDialog-root.project-kpi-systems-dialog {
  .MuiDialog-paper {
    min-width: 600px;
    max-width: 600px;
  }

  .MuiDialogTitle-root {
    color: #2c2323;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0;
    padding-right: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .loading-overlay {
    overflow: visible;
  }

  .overflow-y-auto {
    max-height: 80vh;
    margin-right: -20px;
    margin-left: -20px;
  }

  .check-tree {
    .check-tree-node {
      padding-bottom: 0;
    }

    ul {
      padding: 0 0 0 30px;
    }

    ul ul {
      .check-tree-node-title {
        padding-left: 20px;
      }
    }

    .check-tree-node-title {
      padding-left: 30px;

      &.even {
        background-color: #019fe30d;
      }
    }
  }

  .buttons {
    margin-top: 10px;

    .MuiButton-root {
      font-size: 0.875rem;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}
