.mono-toggle {
  white-space: nowrap;
  display: flex;
  align-items: center;

  .MuiSwitch-colorPrimary, .MuiSwitch-colorPrimary.Mui-checked {
    color: var(--PRIMARY_BACKGROUND_COLOR) !important;
  }

  .MuiSwitch-track {
    background-color: var(--PRIMARY_BACKGROUND_COLOR) !important;
    opacity: 0.3 !important;
  }

  h5 {
    font-size: 20px;
    line-height: 30px;
    margin: 0 32px 0 0 ;
  }

  label {
    cursor: pointer;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0;
    color: var(--SECONDARY_TEXT_COLOR);
    text-transform: uppercase;
    opacity: 0.5;
    user-select: none;

    &.active {
      color: var(--SECONDARY_TEXT_COLOR);
      opacity: 1;
    }
  }

  &.disabled label {
    cursor: default;
  }
}
