.filter-popover {
  .filter-header {
    cursor: pointer;

    h3 {
      margin: 0 0 18px 0;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.8px;
      color: #000000DE;
      text-transform: uppercase;
    }

    .MuiIconButton-root.collapse-trigger {
      padding: 0;
      margin-left: 2px;
      margin-top: -4px;
      color: #000000DE;
    }
  }

  .check-tree {
    margin-left: -6px;
    margin-top: 0;
  }
}

.filter-popover-popover {
  max-height: 50vh;

  .MuiPopover-paper {
    padding: 20px;

    .check-tree {
      margin: 0;
    }
  }
}
