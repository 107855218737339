.project-group-projects {

  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;
    color: #1C304D;
    margin: 0 0 20px 0;
  }

  .items-amount {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707070;
    margin-top: 13px;
    margin-left: 16px;
  }

  .tooltip-column {
    width: 30px;
    max-width: 30px;

    .MuiIconButton-sizeSmall {
      padding: 0;
    }
  }

  .system-score-column {
    min-width: 200px;
  }

  .with-right-border {
    border-right: 2px dashed var(--NEUTRAL_TEXT_COLOR);
  }

  .loading-overlay {
    margin: 0 -30px -20px -30px;

    .simple-table-sort {
      margin-bottom: 0;

      .MuiTableContainer-root {
        max-height: 420px;
        overflow: auto;
        margin: 0;

        .MuiTableRow-head {
          .MuiTableCell-head {
            position: sticky !important;
            z-index: 1;
            top: 0;
          }
        }

        .MuiTableRow-root {
          .MuiTableCell-root {
            .MuiButtonBase-root {
              position: static;
            }
          }
        }

      }
    }
  }
}
