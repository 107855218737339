.project-header {

  .left-block {
    width: 50%;
    box-sizing: border-box;
    margin-right: 30px;
    padding-right:30px;

    p {
      margin-bottom: 0;
    }

  }

  .right-bar-separator {
    border-right: 3px solid #1C304D7F;
  }

  .right-block {
    margin-bottom: -30px;
  }

  .secondary-block {
    display: flex;
    flex-wrap: wrap;
    margin-right: -52px;

    .data-block {
      margin-right: 53px;
      margin-bottom: 30px;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;

      &.location {
        justify-content: center;
        background-image: url('../../../svg/location_on_black_24dp.svg');
        background-repeat: no-repeat;
        background-position: left center;
        padding-left: 60px;
        max-width: 25%;
        min-height: 48px;
        overflow: hidden;

        .value {
          font-size: 14px;
          line-height: 19px;
        }
      }

      &.marginLeft {
        margin-left: auto;
      }
      h2 {
        margin-bottom: 19px;
      }

      .value {
        font-size: 36px;
        line-height: 48px;
        color: #1C304D;

        &.large {
          font-size: 60px;
          line-height: 79px;
        }

        &.budget {
          position: relative;
          top: 25px;
          left: 10px;
          margin-bottom: 1em;
        }

        &.colored {
          color: var(--PRIMARY_BACKGROUND_COLOR);
        }
      }

      label {
        font-size: 14px;
        line-height: 19px;
        color: #1C304D;

        &.large {
          font-size: 20px;
          margin: 1px;
          text-align: right;
          clear: both;
        }
      }
      .row {
        display: table;

      }
      .cell {
        white-space: nowrap;
        display: table-cell;
        vertical-align: middle;
        text-align:left;
      }
    }
  }
}


