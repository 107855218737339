.status-select {

  &.MuiFormControl-root {
    margin-top: 10px;
  }

  .MuiSelect-root.MuiSelect-select {
    background: transparent;
    padding: 0 24px 0 0;
  }

  .MuiInput-underline:before, .MuiInput-underline.Mui-disabled:before {
    border-bottom: none;
  }

  .MuiSelect-icon {
    transform: translateY(-2px);
  }

  .status {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
  }

  .status-IN_PROGRESS {
    color: var(--ORANGE_COLOR);
  }

  .status-APPROVED, .status-RELEASED {
    color: var(--GREEN_COLOR);
  }

  .status-LOCKED {
    color: #585858;
  }

}
