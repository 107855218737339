.MuiDialog-root .MuiDialog-paper .select-migration-tenant-dialog {
  .MuiDialogTitle-root button {
    top: 0;
  }

  .modal-table.MuiTable-root {
    .MuiTableBody-root {
      .MuiTableCell-body {
        &:last-child {
          padding-right: 21px;
        }
      }
    }
  }
}
