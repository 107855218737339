.indicator-value-range-dialog {

  .MuiDialog-paper {
    width: 480px;
  }

  label {
    margin-bottom: 10px;
    display: inline-block;

    .label {
      display: inline-block;
      line-height: 24px;
    }
  }

  .second-input {
    margin-left: 16px;
    width: 225px;
  }

  h3 {
    margin-bottom: 6px;
  }

  .MuiCheckbox-root {
    padding: 0;
    margin-right: 16px;
  }

  .range-row {
    margin-bottom: 8px;
    display: flex;

    button {
      flex-basis: 40px;
    }
  }

}
