.barDivisionWrapper {
  position: absolute;
  bottom: -10px;
  height: 59px;
  width: 0;
  padding-left: 4px;

  .title {
    position: absolute;
    bottom: 63px;
    transform: translateX(calc( -50% - 2px ));
    white-space: nowrap;
    color: #505050;
    font-size: 12px;
  }

  .year {
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: left;
    top: 85px;
    left: 2px;
    white-space: nowrap;
    color: #505050;
    font-size: 12px;
  }

  .milestone {
    position: absolute;
    bottom: 83px;
    transform: translateX(-5px);
    white-space: nowrap;
    color: #505050;
    font-size: 12px;
  }

  &.first-bar {
    .title {

    }
  }
}

.barDivision-increasing {
  background-color: var(--GREEN_COLOR);
}

.barDivision-decreasing {
  background-color: var(--RED_COLOR);
}

.barDivision-gray {
  background-color: grey;
}

.barDivision-dashed {
  background-color: transparent;
  background-image: url('../../../../gfx/dashed_divider.png');
}
