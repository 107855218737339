.role-data-dialog {
  .MuiFormControl-root {
    margin-bottom: 16px;
  }

  .MuiCheckbox-root {
    margin-right: 4px;
    margin-left: -8px;
    padding: 4px;
  }

  .MuiFormHelperText-root {
    margin-top: -16px;
    position: absolute;
  }

  .permission-group {
    margin-bottom: 20px;

    h3 {
      display: block;
      background: #EFEFEF;
      margin: 0 -31px 12px -31px;
      padding: 8px 31px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
