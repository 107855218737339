.actions-tab {
  &-table {
    max-height: calc(70px + 60px * 6); // headerH + rowH * showAmt
  }

  .k-table-row:not(.k-filter-row) {
    > .k-table-th {
      padding: 0 !important;

      .k-cell-inner {
        padding: 0 !important;
        margin: 0 !important;

        .k-link {
          padding: 0 !important;
          margin: 0 !important;
          text-align: center;
          justify-content: center !important;
        }
      }
    }
  }

  .breadcrumb {
    margin-top: 15px;
    margin-bottom: 24px;
  }

  .kpi-line {
    white-space: nowrap;
    line-height: 23px;
  }

  .action-input-search {
    width: 500px;
  }

  .k-grid-content tr {
    background-color: white;
  }

  .k-grid-content .k-alt {
    background-color: white;
  }

  .action-checkboxes {
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.08);
  }

  .action-check-box {
    margin-right: 20px;
  }

  .k-grid .k-table-th .k-link {
    text-transform: uppercase;
  }

  .k-table-td {
    border-inline-start-color: rgba(0, 0, 0, 0.12) !important;
  }

  .k-grid .k-column-title {
    white-space: normal;
  }

  .k-input-md .k-input-inner,
  .k-picker-md .k-input-inner {
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
  }

  .k-input-solid,
  .k-picker-solid,
  .k-button-solid-base:disabled,
  .k-button-solid-base,
  .k-disabled,
  .k-button {
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
  }

  .k-table-row {
    background-color: white;
  }
}

.export-btns-container {
  display: flex;
}

.export-btns-container Button {
  margin-right: 10px;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: var(--PRIMARY_BACKGROUND_COLOR);
  color: white;
  background-color: var(--PRIMARY_BACKGROUND_COLOR);
}
