.info-dialog.MuiDialog-root {
  .MuiDialog-paper {
    width: 560px;
    max-width: 560px;
  }

  .MuiDialogTitle-root {
    border-bottom: 2px solid #2c2323;
    padding-bottom: 2px;

    &.no-title {
      border-bottom: none;
      padding-bottom: 4px;
    }

    color: #2c2323;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0;
    padding-right: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    button {
      position: absolute;
      right: 18px;
      top: 16px;
    }
  }

  p {
    margin: 20px 0 8px 0;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.29px;
    color: #000000;
  }
}
