

.goalCard {
  width: 413px;
  min-height: 213px;
  border: 3px solid #D4D6DC;
  background-color: var(--PRIMARY_TEXT_COLOR);

  .inner {
    padding: 20px 25px;
  }

  .name {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 15px 0;
    color: #505050;
    word-break: break-word;
  }

  .goalType {
    text-align: center;
    color: #505050;
  }

  .chartContainer {
    height: 130px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .background {
      width: 410px;
      height: 39px;
      background-color: #D0D0D0;
      border-radius: 10px;
      position: relative;

      .progressBar {
        position: absolute;
        height: 39px;
        width: 100%;
        max-width: 100%;
        background-color: var(--PRIMARY_BACKGROUND_COLOR);
        left: 0;
        top: 0;
        border-radius: 10px 0 0 10px;
        border: 3px solid #BBBBBB;
        border-right: none;
        box-sizing: border-box;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    margin: 15px 0 0 0;

    .edit-btn {
      color: var(--SECONDARY_TEXT_COLOR);
    }

    .delete-btn {
      color: var(--RED_COLOR);
    }

  }
}
