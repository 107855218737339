.flex-col-col1 {
    width: 50%;
    margin-top: 10px;
}
.flex-col-col2 {
    display: flex;
    flex-direction: column;
    margin-top: 66px;
    margin-left: 40px;
    width: 50%;
}
.flex-col-col3 {
    width: 100%;
    justify-content: flex-end;
    display: flex;
}
.cell {
    margin-left: auto;
}
.text-field-mandatory {
    color : #000000;
    opacity: 50%;
    text-align: left;
    margin-top: 40px;
}
