.project-group-analyze-main-block {

  .block-header {
    margin: 0;
    line-height: 40px;
    word-break: break-word;
  }

  .description {
    max-height: 129px;
    overflow: auto;
    margin: 20px -30px 0 -30px;
    padding: 0 30px 0 30px;
    word-break: break-word;
  }

  .group-data-container { 
    margin-top: 0.5rem;

    .group-data-info { 
      margin-bottom: 15px;
    }
  }
}
