.project-files {

  .files-empty, .drop-here {
    padding-top: 28px;

    svg {
      margin-bottom: 20px;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0;
      color: #000000;
    }
  }

  .files-footer {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .drop-area {
    position: relative;

    &.read-only {
      min-height: 0;
    }

    .drop-here {
      display: none;
      border: 3px dashed var(--NEUTRAL_TEXT_COLOR);
      border-radius: 10px;
      margin-top: 66px;
    }

    .other {
      display: block;
    }

    &.drag-over {

      * {
        pointer-events: none;
      }

      .drop-here {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      .other {
        opacity: 0;
      }
    }
  }
}
