.status-indicator {
  display: inline-block;
  width: 20px;
  height: 34px;

  .indicator-part {
    width: 20px;
    height: 10px;
    margin-bottom: 2px;
    background: #E5EAF2;
    border-radius: 2px;

    &:last-child {
      margin-bottom: 0;
    }

    &.green {
      background: #007C05;
    }

    &.red {
      background: #FF391D;
    }
  }
}
