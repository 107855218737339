

.sustainability-system-awards {
  padding: 0 50px;
  
  .sustainability-system-awards-box {
    background: var(--PRIMARY_TEXT_COLOR);
    box-shadow: 2px 2px 2px #00000029;
    padding: 2px 20px;
  }

  h2 {
    font-size: 28px;
    font-weight: bold;
    line-height: 37px;
    color: var(--PRIMARY_BACKGROUND_COLOR);
    margin: 30px 0;
  }

  .two-options-toggle {
    display: inline-block;
    margin-left: 20px;
  }

  .between-inputs {
    padding: 0 8px;
    line-height: 32px;
  }

  .filters {
    margin: 16px 0 16px 0;
  }

  .award-cards {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: repeat(auto-fit, 325px);
    margin-bottom: 50px;
  }

  .add-award-card {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: normal;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 356px;
    text-align: center;

    span {
      cursor: pointer;
      color: var(--SECONDARY_TEXT_COLOR);

      svg {
        display: block;
        margin-left: auto;
        margin-right: auto;
        color: var(--SECONDARY_TEXT_COLOR);
        width: 50px;
        height: 50px;
        margin-bottom: 5px;
      }
    }
  }

  .award-card {
    background: var(--PRIMARY_TEXT_COLOR);
    box-shadow: 0 2px 2px #00000029;
    border: 2px solid #D0D0D0;
    box-sizing: border-box;
    padding: 15px 30px;
    text-align: center;

    h3 {
      font-size: 20px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: 0.36px;
      color: #000000;
      margin: 0 0 12px 0;
    }

    h4 {
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      letter-spacing: 0.29px;
      color: #000000;
      opacity: 1;
      margin: 0 0 12px 0;

      &.current-threshold {
        color: var(--PRIMARY_BACKGROUND_COLOR);
      }

      &.potential-threshold {
        color: var(--SECONDARY_BACKGROUND_COLOR);
      }
    }

    .award-image-wrapper {
      height: 200px;
    }

    .MuiButton-root {
      margin-top: 12px;
    }

    .award-image {
      max-width: 240px;
      max-height: 200px;
      cursor: pointer;
    }

    .MuiButton-label {
      font-size: 14px;
      font-weight: normal;
      line-height: 19px;
      text-transform: uppercase;

      svg {
        margin-right: 5px;
        width: 19px;
        height: 19px;
      }
    }

    .edit-btn {
      color: var(--SECONDARY_TEXT_COLOR);
    }

    .delete-btn {
      color: var(--RED_COLOR);
    }
  }

  .systemLogicDescriptionTooltip {
    margin-left: 30px;
  }

  .systemLogicDescriptionIcon {
    font-size: 1.1rem;
  }

  .systemLogicToggle {
    margin-right: 5px;
  }

  .calculationTypePercentDescriptionTooltip {
    margin-left: 30px;
  }

  .calculationTypePointDescriptionTooltip {
    margin-left: 20px;
  }
}
