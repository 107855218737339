

.dashboard-rating-systems {

  .MuiTableBody-root {
    .MuiTableRow-root .MuiTableCell-root {
      cursor: pointer;
    }

    .MuiTableRow-root.selected .MuiTableCell-root {
      cursor: default;
    }
  }

  .content-block-footer {
    text-align: center;
    margin-top: -8px;
    margin-bottom: -22px;

    .collapse-button {
      .MuiButton-label {
        font-size: 14px;
        line-height: 19px;
        color: var(--PRIMARY_BACKGROUND_COLOR);
        text-transform: uppercase;
      }

      svg {
        margin-left: 8px;
        width: 15px;
        height: 15px;
        transform: rotate(90deg);
      }
    }
  }
}
