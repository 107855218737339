.user {
  background-position: center;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 30px;
  margin-right: 20px;
  display:inline-block;
  vertical-align:middle;
}
.user-placeholder {
  background-position: center;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 30px;
  margin-right: 20px;
  width: 140px;
  height: 140px;
  background-repeat: no-repeat;
  background-image: url('../../images/no_picture_user.png');
}
