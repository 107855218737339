.benchmark-goals {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  .divider {
    display: flex;
    border-bottom: 1px solid #767676;
    color: #767676;
    background-color: #ECECEC;
    margin: 4px -30px 10px -30px;
    padding: 0 30px;

    .header-wert {
      flex-basis: 50%;
      padding: 7px 0;
      text-transform: uppercase;
    }

    .header-date {
      flex-basis: 50%;
      padding: 7px 0 7px 4px;
      text-transform: uppercase;
    }
  }

  .field-wrapper {
    margin-right: 50px;
  }

  .goals-datepicker{
    top: 5px;

    &.k-datepicker {
      margin-bottom: 7px;
      border-bottom-width: 2px;
      border-bottom-color: #707070;
    }

    &.k-datepicker:hover {
      border-color: #707070;
    }

    &.k-datepicker::after {
      transform: matrix(0, 0, 0, 0, 0, 0) !important;
    }

    .k-input {
      input {
        padding: 1px !important;
      }
    }

    .k-input-flat:focus-within {
      border-bottom-color: #008dc9 !important ;
    }
  }

  .buttons {
    margin-bottom: -8px;
  }

  .value-button {
    display: flex;
    text-transform: uppercase;
    color: var(--PRIMARY_BACKGROUND_COLOR);
    align-items: stretch;
  }
}
