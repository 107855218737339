.project-group-analytics-kpi-details-chart {

  .kpi-details-chart {
    h2 {
      font-weight: 400;
      font-size: 20px;
      line-height: 38px;
      color: #002965;
      margin-bottom: 30px;
    }
  }

  .projects {
    max-width: 200px;

    .project-item {
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  .value-dot-wrapper {
    position: absolute;
    padding: 5px;
    transform: translate(calc(-100% - 10px), calc(-50% - 1px));
    z-index: 1;

    .value-dot {
      margin: 0;
      pointer-events: none;
    }
  }

  .value-dot {
    min-width: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #c0c0c0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.32);
    margin-right: 8px;
    margin-top: 6px;
  }

  .value-pair-wrapper {
    display: flex;
    justify-content: center;

    .current-wrapper, .potential-wrapper {
      display: flex;
      align-items: flex-end;
    }
  }

  .value-dots {
    height: 100%;

    .value-dot-column {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column-reverse;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }

      .value-dot-bar {
        width: 36px;
      }
    }
  }

}
