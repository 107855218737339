.roles-page.page-with-table {
  .page-header > .flex-col:first-child, .page-layout > .flex-col:first-child {
    width: 305px;
    min-width: 305px;
  }

  .select-tenant {
    width: 368px;
  }

  .not-selected-hint {
    display: flex;
    justify-content: center;
    margin-top: 200px;

    p {
      font-weight: 400;
      font-size: 20px;
      color: #1C304D;
      margin-top: 94px;
      margin-right: 29px;
    }
  }

  .roles-table {
    table-layout: fixed;

    .name-column {
      width: 25%;
    }

    .description-column {
      width: auto;
    }

    .status-column {
      width: 110px;
    }

    .default-column-header {
      width: 110px;
    }

    .actions-column-header {
      width: 130px;
    }

    .actions-column {
      text-align: right;
    }

    .MuiFormGroup-root {

      .MuiTypography-root {
        white-space: nowrap;
        line-height: 32px;
      }

      .MuiIconButton-root {
        padding: 0;
        margin-right: 5px;
      }
    }
  }
}
