.advanced-filter-datepicker {
    top: 5.5px;

    &.k-datepicker {
        margin-bottom: 7px;
        border-bottom-width: 2px;
        border-bottom-color: #707070;
    }

    &.k-datepicker:hover {
        border-color: #707070;
    }

    &.k-datepicker::after {
        transform: matrix(0, 0, 0, 0, 0, 0) !important;
    }

    .k-input {
        input {
        padding: 1px !important;
        }
    }

    .k-input-flat:focus-within {
        border-bottom-color: #008dc9 !important ;
    }
}