.projectsMap {
    height: 600px;
}

.azurePopupStyles {
    padding: 20px;
    color: white;
}

.mapWrapper { 
    #atlas-map-state,
    #atlas-map-style,
    #atlas-map-shortcuts,
    .maplibregl-control-container,
    .atlas-control-container { 
        display: none !important;
    }
}
