.additional-fields-tab-content { 
    .rich-text-content {
        imagecontainer, imagegridcell {
            &.rich-text-image-error {
                svg.rich-text-spinner { 
                    height: 0;
                    width: 0;
                    opacity: 0;
                    visibility: hidden;
                }
    
                svg.rich-text-404 { 
                    width: 256px;
                    height: 256px;
                    opacity: 1;
                    visibility: visible;
                }
    
                img { 
                    display: none !important;
                }
            }
    
            &.rich-text-image-loaded { 
                svg.rich-text-spinner { 
                    height: 0;
                    width: 0;
                    opacity: 0;
                    visibility: hidden;
                }
            }
    
            svg.rich-text-spinner { 
                transition: height 200ms, opacity 200ms, visibility 200ms;
                animation: rotate360 500ms linear infinite;
            }
    
            svg.rich-text-404 { 
                width: 0;
                height: 0;
                opacity: 0;
                visibility: hidden;
                transition: height 200ms, opacity 200ms, visibility 200ms;
            }
        }
    }   
}