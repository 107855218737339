.kpi-data-dialog {

  .dialog-body {
    margin: 10px 0;

    .links-list, .files-list {
      margin-bottom: 20px;
    }

    h2.links-header, h2.files-header {
      .MuiIconButton-sizeSmall {
        padding: 0;
      }

      margin-bottom: 8px;
    }
  }
}
