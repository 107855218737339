.simple-table-sort {
  margin-bottom: 17px;
  background: var(--PRIMARY_TEXT_COLOR);

  .MuiTableContainer-root {

    .single-action-header-cell {
      width: 100px;
    }

    &.repaint {
      overflow-y: hidden;

      .MuiTableHead-root {
        background: #747474;

        .MuiTableCell-head {
          background: #747474;
          color: #FFFFFF;
          padding-top: 12px;
          padding-bottom: 12px;
          border-bottom: none;

          .MuiSvgIcon-root {
            color: #FFFFFF;
          }
        }
      }

      .MuiTableBody-root {
        .MuiTableRow-root {
          &:hover {
            background: #F2F2F2;
            transform: scaleY(1.1);
            box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
            transition: box-shadow 0.2s linear, transform 0.2s linear;

            &:last-child {
              transform: none;
            }

            td {
              border-color: #F2F2F2;
            }
          }

          .MuiTableCell-root {
            padding-top: 12px;
            padding-bottom: 12px;
          }

          &.child-row {
            .MuiTableCell-root {
              height: 32px;
              padding-top: 4px;
              padding-bottom: 4px;
            }
          }
        }
      }

    }

    &.overflow-visible {
      overflow: visible;
    }

    .collapse-control {
      position: absolute;
      right: 5px;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .single-collapse-control {
      @extend .collapse-control;
      position: relative;
      float: left; 
    }

    .collapse-button-compensation {
      width: 30px;
      float: left;
    }

    .collapse-label {
      white-space: nowrap;
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .MuiTableHead-root {
      .MuiTableCell-head {
        height: 46px;
        padding: 9px 24px 9px 30px;
        box-sizing: border-box;
        vertical-align: middle;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 1px;
        color: var(--NEUTRAL_TEXT_COLOR);
        border-bottom: 2px solid #EFEFEF;
        white-space: nowrap;

        &.sticky-column {
          z-index: 99;
          border-right: 2px solid rgb(239, 239, 239);
        }

        .MuiSvgIcon-root {
          margin-left: 5px;
          transform: translateY(3px);
          font-size: 1.5rem;
          color: #000000;
          height: 21px;
          width: 21px;
        }

        .single-collapse-control .MuiSvgIcon-root {
          margin: 0;
          transform: none;
          width: 1em;
          height: 1em;
        }

        &.sortable {
          cursor: pointer;
        }

        &.with-subs {
          .MuiSvgIcon-root {
            margin-bottom: -1px;
          }

          .with-subs-cell {
            display: inline-flex;
            flex-direction: column;
          }

          p {
            width: max-content;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            height: 19px;
            margin: 0;
          }

          .sub {
            font-weight: bold;
            color: var(--PRIMARY_BACKGROUND_COLOR);
            margin-top: 7px;
          }
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {

        &.no-bottom-border .MuiTableCell-root {
          border-bottom-color: transparent;
        }

        .MuiTableCell-root {
          padding: 6px 0 6px 30px;
          box-sizing: border-box;
          height: 60px;
          vertical-align: middle;
          color: #000000;
          font-size: 16px;
          line-height: 21px;
          word-break: break-word;
          border-bottom: 2px solid #EFEFEF;
          border-top: 2px solid #EFEFEF;

          &.sticky-column {
            border-right: 2px solid rgb(239, 239, 239);
            position: sticky !important;
            top: 0;
            left: 0;
            z-index: 99;
            background-color: #FFF;
          }

          .textblock-name { 
            & > * {
              display: inline-block;
              vertical-align: middle;
              margin-right: 15px;
            }
    
            & > .MuiSvgIcon-root {
              color: rgb(245, 194, 66);
            }
          }

          &.with-collapse-control, &.with-collapse-label {
            position: relative;
          }

          &.with-collapse-control {
            padding-right: 36px;
          }

          &:last-child {
            padding-right: 24px;
          }

          &.empty-cell {
            text-align: center;
            color: rgba(0, 0, 0, 0.5);
          }

          &.text-right {
            padding-right: 24px;
            padding-left: 0;
          }
        }

        &.body-header-row {
          .MuiTableCell-root {
            height: 54px;
            padding: 15px 0 15px 30px;

            .MuiButton-root {
              padding: 0;
              height: 24px;
              line-height: 24px;
              letter-spacing: 0.16px;
              color: var(--SECONDARY_TEXT_COLOR);
              font-size: 18px;
              font-weight: bold;
              text-transform: uppercase;

              .MuiSvgIcon-root {
                font-size: 1.7rem;
                position: absolute;
              }
            }
          }
        }
      }
    }

    .MuiTablePagination-selectRoot {
      .MuiSelect-select.MuiSelect-select {
        line-height: 17px;
      }
    }

    .MuiTableRow-root.collapse-open {
      .MuiTableCell-root {
        border-color: transparent;
        transition: 0s;
      }

      & + .collapse-row {
        .MuiTableCell-root {
          transition: opacity .35s ease-in;
          opacity: 1;
        }
      }
    }

    .MuiTableRow-root:not(.collapse-open) {
      .MuiTableCell-root {
        transition: .35s ease-out;
      }

      & + .collapse-row {
        .MuiTableCell-root {
          transition: opacity .15s ease-out;
          opacity: 0;
          border: none;
        }
      }
    }
  }

}
